import React from "react";
import ReactDOM from "react-dom/client";
import "./index.sass";
import App from "./App";
import { Auth0Provider } from "./react-auth0-spa";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "msal/msal-config";
import { Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import { WebSocketProvider } from "utils/WebSockets/WebSocketProvider";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WebSocketProvider>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        client_id={AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        scope="openid read:appointments"
        audience={AUTH0_AUDIENCE}
        onRedirectCallback={onRedirectCallback}
      >
        <MsalProvider instance={msalInstance}>
          <Router history={history}>
            <App />
          </Router>
        </MsalProvider>
      </Auth0Provider>
    </WebSocketProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
