import { Button } from "antd";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./clientRescheduleNotAvailable.scss";
import RescheduleProblem from "assets/rescheduleProblem.svg";
import { appointments } from "model/calender/appointments";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "./staticRescheduleData";

interface LocationState {
  appointment: appointments;
  activeDay: string;
  path: string;
}

const ClientRescheduleNotAvailable: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { setState } = useContext(myContext);

  const { appointment, activeDay, path } = location?.state;

  const handleNavigateHome = () => {
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    if (path === "appointments")
      history.push({
        pathname: "/client-appointment-redirect",
        state: {
          appointment: appointment,
          activeDay: activeDay,
          path: path,
        },
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  return (
    <div
      id="Reschedule-unavailable"
      className="client-reschedule-not-available-container"
    >
      <div className="client-reschedule-not-available-inner-container-top">
        <span className="client-reschedule-not-available-heading">
          Looks like that time slot is no longer available
        </span>

        <img
          className="client-reschedule-not-available-image"
          src={RescheduleProblem}
          alt="confirmed rescheduled"
        />
      </div>
      <div className="client-reschedule-not-available-inner-container-bottom">
        <span>
          Unfortunately, the appointment date and time you selected for{" "}
          {appointment.client} is no longer available. To reschedule{" "}
          {appointment.client}'s appointment, you can return to the scheduling
          page and choose a new date and time that works best for you.
        </span>
        <div className="client-reschedule-not-available-button">
          <Button
            id="Reschedule-new-time"
            className="client-reschedule-not-available-home"
            onClick={handleNavigateHome}
          >
            Find a new time
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientRescheduleNotAvailable;
