import React, { useContext } from "react";
import "./clientCancel.scss";
import { useHistory, useLocation } from "react-router-dom";
import { appointments } from "model/calender/appointments";
import ClientCancelBar from "./ClientCancelBar";
import { formatAppointmentDateTime } from "../RescheduleAppointments/clientReschedule.helper";
import { Button, Input, Select } from "antd";
import { myContext } from "features/Hooks/useContext";
import { Cancel_Reason, ClientCancelDropdown } from "./staticCancelData";

interface LocationState {
  rcSuggestions: any;
  activeDay: string;
  appointment: appointments;
  path: string;
  payload: any;
}

interface ContextType {
  state: any;
  setState: React.Dispatch<React.SetStateAction<string>>;
  cancelDropDown: any;
  setCancelDropDown: React.Dispatch<React.SetStateAction<any>>;
}

const ClientCancel: React.FC<{}> = () => {
  const location = useLocation<LocationState>();
  const History = useHistory();
  const { state, setState, cancelDropDown, setCancelDropDown } =
    useContext<ContextType>(myContext);
  const { textArea } = state;
  const { TextArea } = Input;
  const { rcSuggestions, activeDay, appointment, path, payload } =
    location?.state;

  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`, "[reschedule] selected dropdown");
    setCancelDropDown(value);
  };

  const handleTextData = (value: string) => {
    console.log(value, "[reschedule] textArea");
    setState((prevState: any) => ({ ...prevState, textArea: value }));
  };

  const handleCancelAppointment = () => {
    History.push({
      pathname: "/client-cancel-review",
      state: {
        rcSuggestions: rcSuggestions,
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
        cancelDropDown: cancelDropDown,
      },
    });
  };

  const handleBackButton = () => {
    History.push({
      pathname: "/client-cancel-details",
      state: {
        activeDay: activeDay,
        appointment: appointment,
        path: path,
        payload: payload,
      },
    });
  };

  const ButtonDisable = cancelDropDown === Cancel_Reason;

  console.log(cancelDropDown, path, "[client cancel] dropdown");

  return (
    <div className="client-cancel-appointment-main-container">
      <div className="client-cancel-appt-inner-container">
        <div className="client-cancel-appt-inner-top-container">
          <div className="client-cancel-appt-text">
            <span className="client-cancel-appt-heading">
              Let us know why {appointment?.client} won’t be at their
              appointment
            </span>
            <span className="client-cancel-appt-sub-text">
              {appointment?.client}’s {appointment?.title} appointment is
              scheduled for:{" "}
              <strong>
                {formatAppointmentDateTime(
                  appointment?.start,
                  appointment?.end
                )}
              </strong>
            </span>
            <span className="client-cancel-appt-sub-text">
              Select a cancellation reason from the dropdown below.
            </span>
          </div>
          <div className="client-cancel-appt-select-feild">
            <Select
              id="Cancel-Reason"
              onChange={handleChange}
              style={{ width: "100%" }}
              options={ClientCancelDropdown}
              value={cancelDropDown}
            />
            <div
              className={cancelDropDown === 7 ? "displayShow" : "displayNone"}
            >
              <TextArea
                rows={4}
                placeholder="Add note (Optional)"
                onChange={(e) => handleTextData(e.target.value)}
                value={textArea}
              />
            </div>
          </div>
        </div>
        <div className="client-cancel-appt-btn">
          <Button
            id="Cancel-back-selection"
            type="default"
            size="large"
            onClick={handleBackButton}
            className="button-all"
          >
            Back
          </Button>
          <Button
            id="Cancel-appointment-selection"
            type="primary"
            size="large"
            className="button-next button-all"
            disabled={ButtonDisable}
            onClick={handleCancelAppointment}
          >
            Cancel appointment
          </Button>
        </div>
      </div>
      {rcSuggestions?.length !== 0 && (
        <ClientCancelBar
          rcSuggestions={rcSuggestions}
          activeDay={activeDay}
          appointment={appointment}
          path={path}
          payload={payload}
        />
      )}
    </div>
  );
};
export default ClientCancel;
