import React, { useState, useCallback, useEffect, useRef } from "react";
import { useAuth0 } from "react-auth0-spa";
import axios from "axios";
import Loader from "features/Loader/Loader";
import { getFromCache, cache } from "./cache.js";
import moment from "moment";
import {
  appointments,
  IAppointments,
  PatientClinic,
} from "model/calender/appointments.js";
import CalenderView from "./Calendar/CalenderView";
import SubHeader from "features/SubHeader/Subheader";
import { isMobile } from "react-device-detect";
import ClientActivities from "./Calendar/Supporting-Components/ClientActivities";
import ClientFAQ from "./Calendar/Supporting-Components/ClientFAQ";
import { ApplicationStages } from "constant/AppConstants";
import { clientClinics } from "./biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "./biportal/AppointmentForms/AllAppointmentsApi";

import "./Appointments.scss";
import SpinnerV2 from "features/Appointments/spinnerV2/spinnerV2";
import Message from "sharedComponents/reactHookComponents/UXComponents/Message";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider.js";
import ClientRescheduleError from "sharedComponents/Error/ClientRescheduleError";
// import { useLocation } from "react-router-dom";

const CACHED_APPOINTMENTS_KEY: String = "appointments";
// interface LocationState {
//   prevDate: any;
// }

const Appointment: React.FC<{}> = () => {
  const { loading, user, getTokenSilently } = useAuth0();
  // const location = useLocation<LocationState>();
  // const refDate = location.state?.prevDate || undefined;

  // console.log(refDate, "refDate in the appointments");

  const pollingTime = 30000;

  const [appointments, setAppointments] = useState<appointments[]>(
    getFromCache(CACHED_APPOINTMENTS_KEY) || []
  );
  const [backendError, setBackendError] = useState<Boolean>(false);
  const [clinicBackendError, setClinicBackendError] = useState<Boolean>(false);
  const [softloading, setsoftloading] = useState<boolean>(false);
  const [patientClinic, setPatientClinic] = useState<number[]>([]);
  const [hasComponentMounted, setComponentMounted] = useState<boolean>(true);
  const [pollCount, setPollCount] = useState<number>(0);
  const [freezePoll, setFreezPoll] = useState<boolean>(false);
  const [StartDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const pollCountRef = useRef<number>(0);
  const startDateRef = useRef<string>("");
  // const endDateRef = useRef<string>("");
  const [syncActionError, setSyncActionError] = useState<boolean>(false);
  const [syncActionMsg, setSyncActionMsg] = useState<string>("update");
  const [syncActionDate, setSyncActionDate] = useState<string>();


  const [noApptsRecord, setApptsRecords] = useState<Boolean>(false);
  console.log("user data logged", user);

  const { webSocketData } = useWebSocket();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData.athena !== true || webSocketData.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  const showClientActivities = false;

  const getAppointments = useCallback(
    async (startDate: string, endDate: string, setLoading: boolean) => {
      startDateRef.current = startDate;
      setStartDate(startDate);
      setEndDate(endDate);
      const accessToken = await getTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const payLoad = {
        user_id: user.sub,
        isProvider: false,
        startDate: startDate,
        endDate: endDate,
        email: user.email,
      };

      const apiUrl =
        process.env.REACT_APP_STAGE === ApplicationStages.local
          ? `http://localhost:8000/appointments`
          : `${process.env.REACT_APP_BACKEND_API}/appointments`;

      setsoftloading(setLoading);
      try {
        const resp = await axios.post(apiUrl, payLoad, config);
        console.log("[appts tsx] resp", resp?.data[0].startDate);
        const errorMessages: { [key: number]: string } = {
          401: "create",
          402: "update",
          403: "cancel",
        };

        let results: appointments[] = [];
        // Extract start date from the response (assuming it's consistent across elements)
        const responseStartDate = resp.data[0]?.startDate;

        if (responseStartDate === startDateRef.current) {
          resp.data.forEach((element: IAppointments, patientIndex: number) => {
            setComponentMounted(false);
            if (element.count === 0) {
              setApptsRecords(true);
            }
            if (element.appointments && Array.isArray(element.appointments)) {
              const filteredAppointments = element.appointments.map((appointment) => {
                if ((appointment.crStatus in errorMessages) &&  moment().diff(moment(appointment.createdAt), 'minutes') <= 5 ) {
                  setSyncActionError(true);
                  setSyncActionMsg(errorMessages[appointment.crStatus]);
                  setSyncActionDate(appointment.startAt);
                }
                return {
                  ...appointment,
                  title: appointment.type_name,
                  start: appointment.startAt,
                  end: appointment.endAt,
                  client: appointment.client_name,
                  provider: appointment.provider_name,
                  crStatusCheck: appointment.crStatus,
                  athenaStatusCheck: appointment.athenaStatus,
                  patientIndex,
                };
              });
  
              results.push(...filteredAppointments);
            }
          });

          results.sort(
            (a: appointments, b: appointments) =>
              new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
          );

          console.log(results, "[getappointments] results");
          console.log(results.length, "[getappointments] results length");

          setAppointments(results);
          cache(CACHED_APPOINTMENTS_KEY, results);
          setBackendError(false);
        } else {
          console.log("Start date mismatch. Data not processed.");
        }
      } catch (err) {
        console.error(err, "[Error in getappointments]");
        setBackendError(true);
      } finally {
        if (startDate === startDateRef.current) {
          setsoftloading(false);
        }
      }
    },
    [getTokenSilently, user.sub, user.email]
  );

  useEffect(() => {
    const getclinics = async () => {
      try {
        let response: any;
        response = await clientClinics(
          ApiCalls.patientClinics,
          getTokenSilently
        );
        if (response) {
          const clinicArrayData = JSON.parse(response?.data);
          setPatientClinic(
            clinicArrayData?.Clinic_Array_Data.filter(
              (clinic: PatientClinic) => clinic.isActive
            ).map((clinic: PatientClinic) => clinic.clinic)
          );
        }
      } catch (error) {
        setClinicBackendError(true);
        console.error("Error fetching clinics:", error);
      }
    };

    getclinics();
  }, [getTokenSilently]);

  const patientPollData = useCallback(
    (poll: boolean, start: any, end: any, load: boolean) => {
      if (poll) {
        getAppointments(start, end, load);
      } else {
        return;
      }
    },
    [getAppointments]
  );

  useEffect(() => {
    if (backendError) {
      return;
    } else if (pollCount > 25) {
      //keep 123 (30 min)
      setFreezPoll(true);
      return;
    } else {
      if (freezePoll === false) {
        const interval = setInterval(() => {
          patientPollData(true, StartDate, endDate, false);
          pollCountRef.current += 1;
          setPollCount(pollCountRef.current);
        }, pollingTime);

        return () => clearInterval(interval);
      } else return;
    }
  }, [
    backendError,
    StartDate,
    endDate,
    pollCount,
    patientPollData,
    freezePoll,
  ]);

  const BackEndError = !backendError && !clinicBackendError ? false : true;

  const getAppointmentsCallback = useCallback(getAppointments, [
    getTokenSilently,
    user.email,
    user.sub,
    getAppointments,
  ]);

  const handleDataChange = useCallback(
    (startStr: string, endStr: string) => {
      getAppointmentsCallback(startStr, endStr, true);
      setPollCount(0);
      pollCountRef.current = 0;
    },
    [getAppointmentsCallback]
  );

  useEffect(() => {
    const currentDate: Date = new Date();
    handleDataChange(
      moment(currentDate).startOf("day").toISOString(),
      moment(currentDate).endOf("day").toISOString()
    );
  }, []);

  useEffect(() => {
    const ismobile = isMobile;
    if (ismobile) {
      const currentDate: Date = new Date();
      getAppointmentsCallback(
        moment(currentDate).startOf("day").toISOString(),
        moment(currentDate).endOf("day").toISOString(),
        true
      );
    }
  }, [getAppointmentsCallback]);

  if (loading || !user) {
    return <Loader />;
  }

  const getAppointmentsTable = (appointments: appointments[]) => (
    <CalenderView
      appointments={appointments}
      handleDataChange={handleDataChange}
      softloading={softloading}
      patientClinic={patientClinic}
      // refDate={refDate}
      pollCount={pollCount}
      displayBanner={displayBanner}
    />
  );

  let client = user?.name ? user.name : user?.nickname;

  console.log(pollCount, "[patientAppoitments] pollCount");

  console.log(
    backendError,
    clinicBackendError,
    "[backendError in appointmentspage]"
  );

  return (
    <>
      {freezePoll ? (
        <>
          <Message setPollCount={setPollCount} setBoolean={setFreezPoll} />
        </>
      ) : (
        <>
          <SubHeader client={client} isLoading={softloading} />
          {BackEndError ? (
            <ClientRescheduleError BackEndError={BackEndError}
            />
          ) : noApptsRecord ? (
            <div className="no-records">
              <span className="global-content-header">Appointments (0)</span>
              <p className="para-sm">
                We don’t have any appointments for you at this time. We’d like
                to get you scheduled.
              </p>
            </div>
          ) : !hasComponentMounted ? (
            <>
            {syncActionError ? (
              <ClientRescheduleError 
                syncActionError={syncActionError}
                syncActionMsg={syncActionMsg}
                syncActionDate={syncActionDate}
              />
            ) : null}
            {getAppointmentsTable(appointments)}
            </>
          ) : hasComponentMounted ? (
            <>
              <div className="no-appointments">
                <div className="spinner-calender-view">
                  <SpinnerV2 />
                </div>
                <p className="no-appointments-1">Loading Appointments Data</p>
              </div>
            </>
          ) : (
            <></>
          )}

          {showClientActivities && <ClientActivities />}

          <ClientFAQ />
        </>
      )}
    </>
  );
};

export default React.memo(Appointment);
