import { Button } from "antd";
import { appointments } from "model/calender/appointments";
import React, { useCallback, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ClientCancelBar from "./ClientCancelBar";
import "./clientCancelReview.scss";
import {
  CustomSelectOption,
  default_Dropdown,
} from "../RescheduleAppointments/staticRescheduleData";
import calender from "assets/CalendarBlank.svg";
import RescheduleMapPin from "assets/RescheduleMapPin.svg";
import RescheduleClock from "assets/RescheduleClock.svg";
import Rescheduleuser from "assets/RescheduleUser.svg";
import { IAddress } from "model/appointments";
import moment from "moment";
import { DiffMinute } from "../RescheduleAppointments/clientReschedule.helper";
import { Cancel_Reason, ClientCancelDropdown } from "./staticCancelData";
import { myContext } from "features/Hooks/useContext";
import Spinner from "features/Appointments/Spinner/spinner";
import { clientRescheduleAndCancelCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { useAuth0 } from "react-auth0-spa";
import Warning from "assets/WarningAmberOutlined.svg";
import { Typography } from "@mui/material";
import "../../../../../sharedComponents/Error/errorMessage.css";

interface LocationState {
  rcSuggestions: any;
  activeDay: string;
  appointment: appointments;
  path: string;
  payload: any;
  cancelDropDown: any;
}

interface ContextType {
  state: any;
  confirmLoading: Boolean;
  setConfirmLoading: React.Dispatch<React.SetStateAction<Boolean>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  setGlobalPath: React.Dispatch<React.SetStateAction<any>>;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  setCancelDropDown: React.Dispatch<React.SetStateAction<string>>;
}

const ClientCancelReview: React.FC<{}> = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const {
    state,
    setState,
    confirmLoading,
    setConfirmLoading,
    setGlobalPath,
    setPrefDate,
    setCancelDropDown,
  } = useContext<ContextType>(myContext);
  const { getTokenSilently } = useAuth0();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const { textArea } = state;

  const {
    rcSuggestions,
    activeDay,
    appointment,
    path,
    payload,
    cancelDropDown,
  } = location?.state;

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === "Telehealth") {
      address = appointment?.link;
    } else {
      appointment?.location_type === "In Clinic"
        ? (address = `${appointment.clinic.name} Clinic`)
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const AppointmentDetails: any = [
    {
      src: calender,
      alt: "calendar",
      text: moment(appointment?.start).format("dddd, MMMM D, YYYY"),
    },
    {
      src: RescheduleClock,
      alt: "clock",
      text: `${moment(appointment?.start).format("h:mm a")} - ${moment(
        appointment?.end
      ).format("h:mm a")}`,
    },
    {
      src: Rescheduleuser,
      alt: "User",
      text: `${appointment.provider.split(", ").join(" ")}, ${
        appointment.speciality
      }`,
    },
    {
      src: RescheduleMapPin,
      alt: "pin",
      text: address,
    },
  ];

  const selectedOption = ClientCancelDropdown?.find(
    (option: CustomSelectOption) => option.value === cancelDropDown
  );

  const LabelData = selectedOption?.label;
  const LabelKey = selectedOption?.key;

  console.log(LabelData, LabelKey, "[clientCaneclReview] label");

  const handleBackButton = () => {
    history.push({
      pathname: "./client-cancel-appointment",
      state: {
        rcSuggestions: rcSuggestions,
        activeDay: activeDay,
        appointment: appointment,
        path: path,
        payload: payload,
      },
    });
  };

  const duration = DiffMinute(appointment.start, appointment.end);

  const handleCancelAppointment = useCallback(async () => {
    setConfirmLoading(true);
    const Cancelpayload = {
      eventId: appointment?.id,
      clinicPreference: appointment?.clinicPreferenc,
      isPendingConfirmation: false,
      startDate: appointment?.start,
      endDate: appointment?.end,
      duration: duration,
      locationType: appointment?.location_type,
      locationCategory: appointment?.location_category,
      telehealthLink: appointment?.link,
      paymentMethod: appointment?.paymentType,
      appointmentTypeId: appointment?.subtype_id,
      providerId: appointment?.provider_id,
      clientId: appointment?.client_id,
      clinicId: appointment?.clinic?.id,
      startingAddress: null,
      destinationAddress: null,
      miles: null,
      isLastDrive: false,
      reimburseableMiles: null,
      cancelReasonId: LabelKey,
      notes:
        LabelData === "Other"
          ? LabelData + ` from Patient Portal >> ` + textArea
          : LabelData + ` from Patient Portal`,
      clientEmailId: appointment?.client_email,
    };
    console.log(Cancelpayload);

    try {
      const response = await clientRescheduleAndCancelCall(
        ApiCalls.patientCancelcall,
        Cancelpayload,
        getTokenSilently
      );
      if (response && response.data) {
        setConfirmLoading(false);
        if (response.data?.haveConflictingEvents === true) {
          setErrorMessage(true);
        } else {
          history.push({
            pathname: "./client-appointment-cancelled",
            state: {
              appointment: appointment,
              path: path,
            },
          });
          console.log(response, "cancel confirm done");
        }
      } else {
        setConfirmLoading(false);
        setState((prevData: any) => ({
          ...prevData,
          selectedDate: null,
          initialDateSet: false,
          clickedIndex: -1,
          dropdown: default_Dropdown,
          textArea: "",
          rcSuggestions: undefined,
          error: false,
          newStartTime: undefined,
          newEndTime: undefined,
        }));
        console.error("Error: No data received from API response");
      }
    } catch (err: any) {
      setGlobalPath(() => ({
        to: "appointments",
        from: "cancelFlow",
      }));
      setConfirmLoading(false);
      setPrefDate(appointment?.start);
      history.push({
        pathname: "./client-cancel-problem",
        state: {
          appointment: appointment,
          path: path,
        },
      });
      console.error("Error in API call:", err);
    }
  }, [
    getTokenSilently,
    textArea,
    LabelData,
    LabelKey,
    appointment,
    duration,
    history,
    path,
    setConfirmLoading,
    setGlobalPath,
    setPrefDate,
    setState,
  ]);

  const handlenavigateHome = () => {
    setConfirmLoading(false);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    setErrorMessage(false);
    setCancelDropDown(Cancel_Reason);
    history.push({
      pathname: "./appointments",
    });
  };

  return (
    <>
      {confirmLoading ? (
        <div className="client-cancel-confirm-loading">
          <Spinner />
          <span className="client-cancel-confirm-loading-text">
            Confirming your cancellation. Thanks for your patience!
          </span>
        </div>
      ) : (
        <div className="client-cancel-review-main-container">
          {errorMessage && (
            <div className="Client-cancel-error-message-container">
              <div className="Client-cancel-error-message">
                <img
                  className="Client-cancel-Warning-img"
                  src={Warning}
                  alt="Warning"
                />
                <Typography>
                  <p className="Client-cancel-error-message-1">
                    The appointment for{" "}
                    {moment(appointment?.start).format("MMMM Do [at] h:mm a")}{" "}
                    has already been cancelled by the Scheduling Team. Head back
                    to the homepage to update another appointment.
                  </p>
                </Typography>
              </div>
            </div>
          )}
          <div className="client-cancel-review-inner-container">
            <div className="client-cancel-review-inner-top-container">
              <div className="client-cancel-review-text">
                <span className="client-cancel-review-heading">
                  Time to review and confirm the cancellation
                </span>
                <span className="client-cancel-review-sub-text">
                  Please confirm if you'd like to proceed with cancelling the
                  appointment below. If you need to adjust the cancellation
                  reason, simply go back and make the change.
                </span>
              </div>
              <div className="client-cancel-review-box">
                <div className="client-cancel-box-top">
                  <span className="client-cancel-box-heading">
                    {appointment?.title} Appointment for {appointment?.client}{" "}
                  </span>
                  <span className="client-cancel-box-sub-heading">
                    Cancellation reason: {LabelData}
                  </span>
                </div>
                <div className="client-cancel-box-bottom">
                  {AppointmentDetails.map((data: any) => {
                    return (
                      <div className="client-cancel-details-box-container">
                        <img src={data?.src} alt={data?.alt} />
                        <del
                          title={data?.text}
                          className="client-cancel-details-inner-del"
                        >
                          {data?.text}
                        </del>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="client-cancel-review-btn">
              {!errorMessage && (
                <Button
                  id="Cancel-review-abandonment"
                  type="default"
                  size="large"
                  onClick={handleBackButton}
                  disabled={errorMessage}
                  className="button-all"
                >
                  Back
                </Button>
              )}
              <Button
                id="Cancel-review-continue"
                type="primary"
                size="large"
                className={
                  errorMessage ? "button-home" : "button-next button-all"
                }
                onClick={
                  errorMessage ? handlenavigateHome : handleCancelAppointment
                }
              >
                {errorMessage ? "Home" : "Confirm"}
              </Button>
            </div>
          </div>
          {rcSuggestions?.length !== 0 && (
            <ClientCancelBar
              rcSuggestions={rcSuggestions}
              activeDay={activeDay}
              appointment={appointment}
              path={path}
              payload={payload}
              errorMessage={errorMessage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ClientCancelReview;
