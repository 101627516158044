const shouldRenderFooterAndNavbar = (pathname) => {
  const noRenderPaths = [
    "/logout",
    "/password-reset-success",
    "/providerappointments",
    "/add-new-appointment",
    "/confirm-schedule",
    "/cancel-appointment",
    "/edit-appointment",
    "/appointments-availability-days",
    "/appointments-availability-builder",
    "/appointments-availability",
    "/appointments-availability-review",
    "/appointments-availability-start",
    "/appointments-availability-builder-review",
    "/appointments-availability-close",
    "/edit-appointments-availiability",
    "/redirect-availability",
    "/avail",
    "/client-appointment-redirect",
    "/client-reschedule-appointment",
    "/client-reschedule-busy",
    "/client-rescheduled",
    "/client-reschedule-problem",
    "/client-reschedule-late",
    "/client-reschedule-confirm",
    "/client-reschedule-not-available",
    "/client-cancel-appointment",
    "/client-cancel-details",
    "/client-cancel-review",
    "/client-cancel-late",
    "/client-appointment-cancelled",
    "/client-cancel-problem",
  ];
  return !noRenderPaths.includes(pathname);
};

const shouldRenderHeader = (pathname) => {
  const noHeaderRenderPaths = ["/provider"];
  return !noHeaderRenderPaths.includes(pathname);
};

export { shouldRenderFooterAndNavbar, shouldRenderHeader };
