import React, { useContext, useEffect } from "react";
import "./clientRescheduled.scss";
import confirmRescheduled from "assets/RescheduledConfirm.svg";
import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";
import {
  rescheduledDate,
  startTimeAndendtime,
} from "./clientReschedule.helper";
import { appointments } from "model/calender/appointments";
import { default_Dropdown } from "./staticRescheduleData";
import { IAddress } from "model/appointments";

interface LocationState {
  appointment: appointments;
  path: string;
  newStartTime: string;
  newEndTime: string;
}

interface ContextType {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  setValidateUrl: React.Dispatch<React.SetStateAction<boolean>>;
  setGlobalPath: React.Dispatch<React.SetStateAction<any>>;
}

const ClientRescheduled: React.FC<{}> = () => {
  const history = useHistory();
  const { state, setState, setPrefDate, setValidateUrl, setGlobalPath } =
    useContext<ContextType>(myContext);
  const location = useLocation<LocationState>();

  const { selectedDate } = state;

  const { appointment, path, newStartTime, newEndTime } = location.state;

  const rescheduledNewDate = rescheduledDate(newStartTime);

  const rescheduleNewTime = startTimeAndendtime(newStartTime, newEndTime);

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === "Telehealth") {
      address = appointment?.link;
    } else {
      appointment?.location_type === "In Clinic"
        ? (address =
            Object.entries(appointment?.address as IAddress)
              .filter(
                ([key, value]) =>
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  key !== "addressLine2"
              )
              .map(([key, value]) => key !== null && `${value}`)
              .join(", ") + ", Clinic")
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const addressData =
    appointment?.location_type === "Telehealth" ? (
      <a
        className="Client-rescheduled-telehealth-addresss"
        href={address}
        title={address}
        target="_blank"
      >
        {address}
      </a>
    ) : (
      address
    );

  // useEffect(() => {
  //   if (!appointment) {
  //     history.push({
  //       pathname: "/appointments",
  //     });
  //   }
  // }, [appointment, history]);

  const handleNavigateHome = () => {
    setPrefDate(newStartTime);
    setGlobalPath(() => ({
      to: "appointments",
      from: "rescheduleFlow",
    }));
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  useEffect(() => {
    setValidateUrl(true);
  }, [setValidateUrl]);

  console.log(path, "[reschedule] Rescheduled");
  return (
    <div id="Reschedule-completed" className="client-rescheduled-container">
      <div className="client-rescheduled-inner-container-top">
        <span className="client-rescheduled-heading">
          Appointment Rescheduled!
        </span>

        <img
          className="client-rescheduled-image"
          src={confirmRescheduled}
          alt="confirmed rescheduled"
        />
      </div>
      <div className="client-rescheduled-inner-container-bottom">
        <span>
          Thank you for rescheduling your {appointment?.client}’s{" "}
          {appointment?.title} appointment.
        </span>
        <span>
          Your new appointment is{" "}
          <strong>
            {rescheduledNewDate} at {rescheduleNewTime} at the {addressData}{" "}
            with {appointment?.provider.split(", ").join(" ")},{" "}
            {selectedDate?.provider?.speciality?.abbreviation}.
          </strong>
        </span>
        <span>
          If you have any questions before your appointment, please contact our
          Customer Care team at{" "}
          <a className="reschedule-late-tag" href="tel:8888855068">
            888-855-5068
          </a>
          , option 2 from 4:30 am-6:00 pm PST Outside of business hours,{" "}
          <a
            className="reschedule-busy-tag"
            href="mailto:customercare@corticacare.com "
          >
            email us
          </a>{" "}
          and we'll handle it first thing in the morning.
        </span>
        <div className="client-rescheduled-button">
          <Button
            className="client-rescheduled-home"
            onClick={handleNavigateHome}
          >
            {path === "appointments" ? "Home" : "All Appointments"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientRescheduled;
