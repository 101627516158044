import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import "./AllAppointmentsList.scss";
import { useAuth0 } from "react-auth0-spa";
import {
  appointments,
  IAppointments,
  PatientClinic,
} from "model/calender/appointments.js";
import axios from "axios";
import ClientAppointmentBar from "./ClientAppointmentBar";
import { Days, calenderViewOption, CALENDAR_VIEW } from "constant/calenderView";
import SpinnerV2 from "features/Appointments/spinnerV2/spinnerV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import { ButtonDates, ClientColor } from "model/calender/calenderView";
import { useHistory } from "react-router-dom";
import { ApplicationStages, EVENT_STATUS } from "constant/AppConstants";

import { clientClinics } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { myContext } from "features/Hooks/useContext";
import Message from "sharedComponents/reactHookComponents/UXComponents/Message";
import { Tabs, TabsProps } from "antd";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import ClientRescheduleError from "sharedComponents/Error/ClientRescheduleError";

const AllAppointmentsList: React.FC = () => {
  const history = useHistory();
  const { user, getTokenSilently } = useAuth0();
  const { prefDate, setPrefDate } = useContext(myContext);

  const selectedOption = calenderViewOption[2].value;
  const calendarRefCustom = useRef<any>(null);
  const calendarRef = useRef<null>(null);
  const dayRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [appointments, setAppointments] = useState<appointments[]>();
  const [backendError, setBackendError] = useState<Boolean>(false);
  const [clinicBackendError, setClinicBackendError] = useState<Boolean>(false);
  const [softloading, setsoftloading] = useState<boolean>(false);
  const [coloredAppointments, setColoredAppointments] = useState<any>([]);
  const [cancelledAppointments, setCancelledAppointments] = useState<any>();
  const [activeDay, setActiveDay] = useState<string>(new Date().toString());
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [responseDate, setResponseDate] = useState<string>("");
  const [buttonDates, setButtonDates] = useState<ButtonDates[]>([]);
  const [patientClinic, setPatientClinic] = useState<number[]>();
  const [sunday, setSunday] = useState<number>(0);

  const buttonDatesRef = useRef<ButtonDates[]>(buttonDates);
  const startDateRef = useRef<string>(startDate);
  const endDateRef = useRef<string>(endDate);
  const sundayRef = useRef<number>(sunday);
  const [currentMonthYear, setCurrentMonthYear] = useState<any>(
    moment().format("MMMM YYYY")
  );
  const [pollCount, setPollCount] = useState<number>(0);
  const [freezePoll, setFreezPoll] = useState<boolean>(false);
  const pollCountRef = useRef<number>(0);
  const allApptstartDateRef = useRef<string>("");

  const path = "appointments";
  const upcoming_Tab = "Upcoming";
  const cancelled_Tab = "Cancelled";

  const [tabsData, setTabsData] = useState<string>(upcoming_Tab);

  const pollingTime = 15000;

  const refdate1 = prefDate
    ? moment(prefDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : undefined;

  console.log(backendError);
  const { webSocketData } = useWebSocket();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData.athena !== true || webSocketData.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  useEffect(() => {
    const currentDate = new Date().toDateString();
    const buttonDates = buttonDatesRef.current;
    const todayIndex = buttonDates.findIndex(
      (buttonDate) => buttonDate.date.toDateString() === currentDate
    );
    const currentWeek = moment().isoWeek();

    if (buttonDates[1]?.week === currentWeek) {
      setTimeout(() => {
        let divElement;
        if (tabsData === upcoming_Tab) {
          divElement = document.getElementById(`appointment-ids${todayIndex}`);
        } else {
          divElement = document.getElementById(
            `cancelled-appointment-ids${todayIndex}`
          );
        }
        if (divElement) {
          divElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    } else {
      setTimeout(() => {
        let divElement;
        if (tabsData === upcoming_Tab) {
          divElement = document.getElementById(`appointment-ids${1}`);
        } else {
          divElement = document.getElementById(`cancelled-appointment-ids${1}`);
        }
        if (divElement) {
          divElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
    // }
  }, [softloading, buttonDates, tabsData]);

  useEffect(() => {
    const getclinics = async () => {
      try {
        let response: any;
        response = await clientClinics(
          ApiCalls.patientClinics,
          getTokenSilently
        );
        if (response) {
          const clinicArrayData = JSON.parse(response?.data);
          setPatientClinic(
            clinicArrayData?.Clinic_Array_Data.filter(
              (clinic: PatientClinic) => clinic.isActive
            ).map((clinic: PatientClinic) => clinic.clinic)
          );
        }
      } catch (error) {
        setClinicBackendError(true);
        console.error("Error fetching clinics:", error);
      }
    };

    getclinics();
  }, [getTokenSilently]);

  async function getAppointments(
    startDate: string,
    endDate: string,
    setLoading: boolean
  ) {
    allApptstartDateRef.current = startDate;
    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const payLoad = {
      user_id: user.sub,
      isProvider: false,
      startDate: startDate,
      endDate: endDate,
      email: user.email,
    };

    const apiUrl =
      process.env.REACT_APP_STAGE === ApplicationStages.local
        ? `http://localhost:8000/appointments`
        : `${process.env.REACT_APP_BACKEND_API}/appointments`;

    setsoftloading(setLoading);

    try {
      const resp = await axios.post(apiUrl, payLoad, config);

      let results: appointments[] = [];
      const responseStartDate = resp.data[0]?.startDate;

      if (responseStartDate === allApptstartDateRef.current) {
        resp.data.forEach((element: IAppointments, patientIndex: number) => {
          if (element.appointments && Array.isArray(element.appointments)) {
            results.push(
              ...element.appointments.map((appointment) => ({
                ...appointment,
                title: appointment.type_name,
                start: appointment.startAt,
                end: appointment.endAt,
                client: appointment.client_name,
                provider: appointment.provider_name,
                patientIndex,
              }))
            );
          }
        });

        results.sort(
          (a: appointments, b: appointments) =>
            new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
        );

        console.log(
          startDate,
          startDateRef.current,
          "[allAppointments] startDate"
        );
        setResponseDate(responseStartDate);
        setAppointments(results);
        setBackendError(false);
      } else {
        console.log("Start date mismatch. Data not processed.");
      }
    } catch (err) {
      console.log("[AllAppts] [getAppointments] response", err);
      setBackendError(true);
    } finally {
      console.log(responseDate, "[allappointments] responseDate");
      if (
        moment(startDate)
          .startOf("day")
          .isSame(moment(startDateRef.current).startOf("day"))
      ) {
        setsoftloading(false);
      }
    }
  }

  const BackEndError = !backendError && !clinicBackendError ? false : true;

  const getAppointmentsCallback = useCallback(getAppointments, [
    getTokenSilently,
    user.email,
    user.sub,
  ]);

  const handlePrevButtonClick = () => {
    if (calendarRefCustom.current && calendarRefCustom.current.getApi()) {
      calendarRefCustom.current.getApi().prev();
    }
  };

  const handleNextButtonClick = () => {
    if (calendarRefCustom.current && calendarRefCustom.current.getApi()) {
      calendarRefCustom.current.getApi().next();
    }
  };

  const patientAllAppointmentPollData = useCallback(
    (poll: boolean, start: any, end: any, load: boolean) => {
      if (poll) {
        getAppointments(start, end, load);
      } else {
        return;
      }
    },
    [getAppointments]
  );

  useEffect(() => {
    if (backendError) {
      return;
    } else if (pollCount > 25) {
      //keep 123 (30 min)
      setFreezPoll(true);
      return;
    } else {
      if (freezePoll === false) {
        const interval = setInterval(() => {
          const startDateData = new Date(startDate).toISOString();
          const endDateData = new Date(endDate).toISOString();
          patientAllAppointmentPollData(
            true,
            startDateData,
            endDateData,
            false
          );
          pollCountRef.current += 1;
          setPollCount(pollCountRef.current);
        }, pollingTime);

        return () => clearInterval(interval);
      } else return;
    }
  }, [
    backendError,
    startDate,
    endDate,
    pollCount,
    patientAllAppointmentPollData,
    freezePoll,
  ]);

  const handleDataChange = useCallback(
    (startStr: string, endStr: string) => {
      getAppointmentsCallback(startStr, endStr, true);
      setPollCount(0);
      pollCountRef.current = 0;
    },
    [getAppointmentsCallback]
  );

  const debouncehandleDataChange = debounce(
    (startDate: string, endDate: string) => {
      handleDataChange(startDate, endDate);
    },
    100
  );

  const handleButtonClick = (index: number) => {
    const selectedDate = buttonDates[index].date.toISOString();
    setActiveDay(selectedDate);
    let divElement;
    if (tabsData === upcoming_Tab) {
      divElement = document.getElementById(`appointment-ids${index}`);
    } else {
      divElement = document.getElementById(`cancelled-appointment-ids${index}`);
    }
    if (divElement) {
      divElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const currentWeek: number = moment().isoWeek();
    const currentDate: string = prefDate
      ? moment(prefDate).toISOString()
      : new Date().toString();
    const buttonDates = buttonDatesRef.current;
    if (
      buttonDates.length <= 0 &&
      selectedOption === calenderViewOption[2].value
    ) {
      setActiveDay(currentDate);
    } else {
      if (currentWeek === buttonDates[3]?.week) {
        setActiveDay(currentDate.toString());
      }
    }
  }, [selectedOption, prefDate]);

  useEffect(() => {
    buttonDatesRef.current = buttonDates;
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    sundayRef.current = sunday;
  }, [buttonDates, startDate, endDate, sunday]);

  useEffect(() => {
    const start: Date = new Date(startDate);
    const end: Date = new Date(endDate);

    const dates: ButtonDates[] = [];
    const dayDifference = Math.floor(
      (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)
    );

    if (dayDifference <= 7) {
      let dayCount = 0;

      while (start <= end && dayCount < 7) {
        dates.push({ date: new Date(start), week: moment(start).isoWeek() });
        start.setDate(start.getDate() + 1);
        dayCount++;
      }
    } else {
      return;
    }

    setButtonDates(dates);

    if (
      Days[dates[0]?.date.getDay()] === Days[6] &&
      Days[dates[1]?.date.getDay()] === Days[1]
    ) {
      const momentStartDate: moment.Moment = moment(
        dates[0]?.date,
        "YYYY-MM-DD"
      );

      const calculatedWeekNumber: number = momentStartDate.isoWeek();

      setSunday(calculatedWeekNumber);
    } else {
      const momentStartDate: moment.Moment = moment(
        dates[1]?.date,
        "YYYY-MM-DD"
      );

      const calculatedWeekNumber: number = momentStartDate.isoWeek();

      setSunday(calculatedWeekNumber);
    }
  }, [startDate, endDate, sunday]);

  const handlePlannerDateViewChange = useCallback(
    (dateArgs: any) => {
      const { startStr, endStr } = dateArgs;
      const parsedStartData: moment.Moment = moment(startStr);
      const formattedStartDate: string = parsedStartData
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      parsedStartData.set({ minute: 0, second: 0, millisecond: 0 });
      let start = updateMonthYear(parsedStartData.toDate());

      const parsedEndData: moment.Moment = moment(endStr);
      const formattedEndDate: string = parsedEndData
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      parsedEndData.set({ minute: 0, second: 0, millisecond: 0 });
      let end = updateMonthYear(parsedEndData.toDate());
      const formattedWeek = parsedEndData.isoWeek();
      const mondayDateOfEveryWeek = prefDate
        ? moment(prefDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : moment().week(formattedWeek).day("Monday");

      const currentWeek: number = moment().isoWeek();
      const currentDate: string = prefDate
        ? moment(prefDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : new Date().toString();
      if (start === end) {
        setCurrentMonthYear(start);
      } else {
        setCurrentMonthYear([(start = start), (end = end)]);
      }
      if (
        selectedOption === CALENDAR_VIEW.dayView &&
        currentWeek !== formattedWeek
      ) {
        setActiveDay(mondayDateOfEveryWeek.toString());
        debouncehandleDataChange(formattedStartDate, formattedEndDate);
      } else if (
        selectedOption === CALENDAR_VIEW.dayView &&
        currentWeek === formattedWeek
      ) {
        setActiveDay(currentDate.toString());
        debouncehandleDataChange(formattedStartDate, formattedEndDate);
      }

      setStartDate(startStr);
      setEndDate(endStr);
      setPrefDate(undefined);
    },
    [selectedOption, debouncehandleDataChange, prefDate, setPrefDate]
  );

  const organizeAppointmentsByDay = (
    appointments: appointments[]
  ): { [key: string]: appointments[] } => {
    const appointmentsByDay: any = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
    };

    appointments?.forEach((appointment) => {
      const startDate = new Date(appointment.start);
      // Get the day of the week for the appointment
      const dayOfWeek = startDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      appointmentsByDay[dayOfWeek].push(appointment);
    });

    return appointmentsByDay;
  };

  const navigateHome = () => {
    history.push({
      pathname: "/appointments",
    });
  };

  useEffect(() => {
    if (appointments) {
      const uniqueClients: Set<number> = new Set(
        appointments.map((a) => a.client_id)
      );

      // Initialize the color map
      const colors: string[] = ["#4a70c6", "#f8d185"];
      let colorCounter: number = 0;
      let clientColorMap: Record<string, ClientColor> = {};

      uniqueClients.forEach((clientId) => {
        const clientAppointment = appointments.find(
          (app) => app.client_id === clientId
        );
        clientColorMap[clientId] = {
          color: colors[colorCounter % colors.length], // Assign a color from the array
          name: clientAppointment ? clientAppointment.client_name : "Unknown",
        };
        colorCounter++;
      });

      // Apply colors to appointments
      appointments.forEach((event: any) => {
        event.color = clientColorMap && clientColorMap[event.client_id]?.color;
      });

      const filteredArray1 = appointments?.filter((item) =>
        item?.scheduledStatus.includes(EVENT_STATUS.canceled)
      );

      const filteredArray2 = appointments?.filter(
        (item) => !item?.scheduledStatus.includes(EVENT_STATUS.canceled)
      );
      setCancelledAppointments([...filteredArray1]);
      setColoredAppointments([...filteredArray2]);
    }
  }, [appointments]);

  const updateMonthYear = (date: Date) => {
    return moment(date).format("MMMM YYYY");
  };
  const getMonthAndYear = (): any => {
    const [firstMonth, firstYear] = currentMonthYear[0].split(" ");
    const [secondMonth, secondYear] = currentMonthYear[1].split(" ");

    if (firstYear === secondYear)
      return `${firstMonth} - ${secondMonth} ${firstYear}`;

    return `${firstMonth} ${firstYear} - ${secondMonth} ${secondYear}`;
  };

  console.log(activeDay, "[allAppointmentList] activeDay");

  console.log(prefDate, "[allApointmentList] prefDate");

  console.log(pollCount, "[allappointment] pollCount");

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: upcoming_Tab,
      children: (
        <>
          {Object.entries(organizeAppointmentsByDay(coloredAppointments))?.map(
            ([day, appointments], index) => {
              const skipSat = day === "Saturday";
              return (
                <div key={index} id={`appointment-ids${index}`}>
                  <h2 className="day-header">
                    {day} ({appointments.length})
                  </h2>
                  {appointments.length === 0 ? (
                    <div className="fn-14">No appointments today</div>
                  ) : (
                    appointments.map((appointment: any, innerIndex: number) => (
                      <div key={innerIndex} className="ClientAppointmentBar">
                        <ClientAppointmentBar
                          key={Number(`${index}-${innerIndex}`)}
                          appointment={appointment}
                          patientClinic={patientClinic}
                          activeDay={activeDay}
                          path={path}
                          displayBanner={displayBanner}
                          pollCount={pollCount}
                        />
                      </div>
                    ))
                  )}
                  {!skipSat && <div className="border-bottom"></div>}
                </div>
              );
            }
          )}
        </>
      ),
    },
    {
      key: "2",
      label: cancelled_Tab,
      children: (
        <>
          {Object.entries(
            organizeAppointmentsByDay(cancelledAppointments)
          )?.map(([day, cancelAppointments], index) => {
            const skipSat = day === "Saturday";
            return (
              <div key={index} id={`cancelled-appointment-ids${index}`}>
                <h2 className="day-header">
                  {day} ({cancelAppointments.length})
                </h2>
                {cancelAppointments.length === 0 ? (
                  <div className="fn-14">No cancelled appointments today</div>
                ) : (
                  cancelAppointments.map(
                    (appointment: any, innerIndex: number) => (
                      <div key={innerIndex} className="ClientAppointmentBar">
                        <ClientAppointmentBar
                          key={Number(`${index}-${innerIndex}`)}
                          appointment={appointment}
                          patientClinic={patientClinic}
                          activeDay={activeDay}
                          path={path}
                          displayBanner={displayBanner}
                          pollCount={pollCount}
                        />
                      </div>
                    )
                  )
                )}
                {!skipSat && <div className="border-bottom"></div>}
              </div>
            );
          })}
        </>
      ),
    },
  ];

  const onChange = (key: string) => {
    console.log(key, "[label]");
    const data = key === "1" ? upcoming_Tab : cancelled_Tab;
    setTabsData(data);
  };

  console.log(tabsData, "[calenderView] tabsData");

  return (
    <>
      {freezePoll ? (
        <>
          <Message setPollCount={setPollCount} setBoolean={setFreezPoll} />
        </>
      ) : !BackEndError ? (
        <div className="appointments-list-wrapper">
          <div ref={calendarRef} className="calender-view">
            <div className="block-header remove-margin">
              <button onClick={navigateHome} className="custom-back-nav">
                <FontAwesomeIcon className="icon-back" icon={faAngleLeft} />
                <span>Home</span>
              </button>
            </div>
            <div className="control-tool-bar">
              <div className="view-picker v-center"></div>
              <div className="other-controls v-center flx-end"></div>
            </div>
            <div
              className={
                selectedOption === CALENDAR_VIEW.dayView
                  ? "disable-cal-control"
                  : "enable-cal-control"
              }
            >
              <div
                className={
                  selectedOption === CALENDAR_VIEW.dayView
                    ? "special-class"
                    : "normal-class"
                }
              >
                <FullCalendar
                  ref={
                    calendarRefCustom as React.MutableRefObject<FullCalendar>
                  }
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  allDaySlot={false}
                  initialView="timeGridCustomView"
                  views={{
                    timeGridCustomView: {
                      type: "timeGrid",
                      duration:
                        selectedOption === CALENDAR_VIEW.threeDayView
                          ? { days: 3 }
                          : { weeks: 1 },
                    },
                  }}
                  slotMinTime="07:00:00"
                  slotMaxTime="22:00:00"
                  events={coloredAppointments}
                  slotEventOverlap={false}
                  headerToolbar={{
                    left: "prev",
                    center: "title today",
                    right: "next",
                  }}
                  //hiddenDays={selectedOption === "Week" ? [0] : []}
                  datesSet={handlePlannerDateViewChange}
                  initialDate={refdate1}
                />
              </div>
            </div>
            <div style={{ minHeight: "50px" }} className="year-and-month">
              <p className="year-and-month-context">
                {typeof currentMonthYear === "string"
                  ? currentMonthYear
                  : getMonthAndYear()}
              </p>
            </div>
            {selectedOption === calenderViewOption[2].value ? (
              <div
                className={
                  isMobile
                    ? "dayAppointements-parent"
                    : "dayAppointements-parent-desktop"
                }
              >
                <div
                  className="dayAppointments"
                  style={{
                    cursor: softloading ? "not-allowed" : "auto",
                  }}
                >
                  <div>
                    <button
                      disabled={softloading}
                      onClick={handlePrevButtonClick}
                      className="custom-nav-control-button"
                    >
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faAngleLeft}
                      />
                    </button>
                  </div>

                  {buttonDates.map((date, index) => (
                    <div
                      ref={(ref) => (dayRefs.current[index] = ref)}
                      className={`dayAppointments-1 ${
                        moment(activeDay, "ddd MMM DD YYYY").isSame(
                          moment(date.date.toDateString(), "ddd MMM DD YYYY"),
                          "day"
                        )
                          ? "active"
                          : ""
                      }`}
                      style={{
                        pointerEvents: softloading ? "none" : "auto",
                      }}
                      key={index}
                    >
                      <div
                        onClick={() => handleButtonClick(index)}
                        className="dayAppointments-group"
                        data-date={date.date.toISOString()}
                      >
                        <div className="dayAppointments-2">
                          {Days[date.date.getDay()]}
                        </div>
                        <div className="dayAppointments-3">
                          {date.date.getDate()}
                        </div>
                      </div>
                    </div>
                  ))}

                  <div>
                    <button
                      disabled={softloading}
                      onClick={handleNextButtonClick}
                      className="custom-nav-control-button"
                    >
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faAngleRight}
                      />
                    </button>
                  </div>
                </div>
                <span className="global-content-header">Appointments</span>

                {/* <button onClick={handleNextButtonClick}>testing button cc</button> */}
                {displayBanner && (
                  <ClientRescheduleError displayBanner={displayBanner} />
                )}

                {softloading ? (
                  <>
                    <div className="no-appointments">
                      <div className="spinner-calender-view">
                        <SpinnerV2 />
                      </div>
                      <p className="no-appointments-1">
                        Loading appointments for the week . . .
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="appointment-list">
                      {coloredAppointments.length === 0 ? (
                        <div className="fn-14">
                          No appointments found for the entire week
                        </div>
                      ) : (
                        <>
                          <Tabs
                            defaultActiveKey="1"
                            items={items}
                            onChange={onChange}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <ClientRescheduleError BackEndError={BackEndError} />
      )}
    </>
  );
};

export default React.memo(AllAppointmentsList);
