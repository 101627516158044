import { APPT_LOCATION_TYPES, EVENT_STATUS } from "constant/AppConstants";
import { formatDateAndTime } from "constant/calenderView";
import { Appointment } from "model/appointments";
import React, { useContext, useEffect } from "react";
import "./ClientAppointment.scss";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Popover } from "@mui/material";
import dots from "assets/DotsThreeVertical.svg";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "../PatientPortalAppointmentForms/RescheduleAppointments/staticRescheduleData";
import { Reschedule_Name } from "../PatientPortalAppointmentForms/RescheduleAppointments/staticRescheduleData";
import { Cancel_Name } from "../PatientPortalAppointmentForms/CancelAppointments/staticCancelData";
import {
  clientAppointmentSyncing,
  isFailedToSync,
} from "features/Appointments/biportal/AppointmentForms/appointments.helper";
import Warning from "assets/WarningAmberOutlined.svg";

interface IProps {
  key: number;
  appointment: Appointment;
  patientClinic?: number[];
  activeDay: string;
  path: string;
  displayBanner: Boolean;
  pollCount?: number;
}

const ClientAppointmentBar: React.FC<IProps> = ({
  key,
  appointment,
  patientClinic,
  activeDay,
  path,
  displayBanner,
  pollCount,
}) => {
  console.log(
    "sravan log",
    key,
    appointment,
    appointment?.color,
    patientClinic,
    activeDay
  );

  console.log(
    patientClinic?.includes(appointment?.home_clinic_id),
    "indcludes log"
  );
  const { setState, setValidateUrl, cancellingAppt, setCancellingAppt } =
    useContext(myContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const History = useHistory();

  const withInDay = moment(appointment?.startAt).diff(moment(), "hours") <= 24;

  console.log(withInDay, "withInday");

  const snackColor = appointment?.color ? appointment.color : "#1d1d1f";

  const isTelehealth =
    appointment.location_type === APPT_LOCATION_TYPES.telehealth;

  const address = appointment.address;
  const apptAddress = [
    address?.addressLine1,
    address?.addressLine2,
    address?.city,
    address?.state,
    address?.zipCode,
  ]
    .filter((part) => part !== undefined && part !== "" && part !== null)
    .join(", ");

  const openMaps = (event: any) => {
    event.stopPropagation();
    const encodedAddress = encodeURIComponent(apptAddress);
    const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRescheduleNavigation = () => {
    setValidateUrl(false);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    History.push({
      pathname: !withInDay
        ? "/client-appointment-redirect"
        : "/client-reschedule-late",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        flow: Reschedule_Name,
      },
    });
  };

  const handleCancelnavigation = () => {
    setValidateUrl(false);
    History.push({
      pathname: !withInDay
        ? "/client-appointment-redirect"
        : "/client-cancel-late",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        flow: Cancel_Name,
      },
    });
  };

  const isFailed =
    !isFailedToSync(appointment?.crStatus) &&
    !isFailedToSync(appointment?.athenaStatus);

  const isSyncing =
    !clientAppointmentSyncing(appointment?.crStatus) &&
    !clientAppointmentSyncing(appointment?.athenaStatus);

  const cancel_success =
    appointment.scheduledStatus === EVENT_STATUS.canceled
      ? true
      : (appointment.parent_type_name === "DT" &&
          appointment.athenaStatus === 103 &&
          appointment.crStatus === 103) ||
        (appointment.parent_type_name !== "DT" &&
          appointment.athenaStatus === 0 &&
          appointment.crStatus === 103)
      ? true
      : false;

  useEffect(() => {
    if (cancellingAppt && pollCount && pollCount > 2) {
      setCancellingAppt(null);
    }
  }, [pollCount, cancellingAppt, setCancellingAppt]);

  const cancellingAppointment =
    appointment.id === cancellingAppt &&
    pollCount !== undefined &&
    pollCount < 2;

  console.log(
    cancellingAppointment,
    cancellingAppt,
    pollCount,
    "[clientAppointmentBar] cancelData"
  );
  return (
    <>
      <style>
        {`
          @keyframes blinkAnimation {
            0%, 50% {
              background-color: rgba(182, 0, 0, 1);
            }
            25% {
              background-color: var(--snack-color);
            }
            75% {
              background-color: var(--snack-color);
            }
            100% {
              background-color: rgba(182, 0, 0, 1);
            }
          }
          .syncing-alert {
            animation: blinkAnimation 3s infinite;
          }
        `}
      </style>
      <div
        className={
          cancel_success ? "appointment-bar failed" : "appointment-bar"
        }
      >
        {!isSyncing || cancellingAppointment ? (
          <div
            className="stack-1 color-indication syncing-alert"
            style={{ "--snack-color": snackColor } as React.CSSProperties}
          ></div>
        ) : !isFailed ? (
          <div
            className="stack-1 color-indication"
            style={
              { backgroundColor: "rgba(182, 0, 0, 1)" } as React.CSSProperties
            }
          ></div>
        ) : (
          <div
            className="stack-1 color-indication"
            style={{ backgroundColor: snackColor }}
          ></div>
        )}

        <div className="stack-2 clock-data uniform-font-size fn-bold">
          <div className="h-center">
            <span>{`${formatDateAndTime(
              appointment.startAt
            ).toLocaleLowerCase()}`}</span>
          </div>
          <div className="h-center">
            <span>{`${formatDateAndTime(
              appointment.endAt
            ).toLocaleLowerCase()}`}</span>
          </div>
        </div>
        <div className="stack-3 other-information uniform-font-size">
          {cancel_success ? (
            <div className="v-center div-4 fn-md">
              <del className="appt-title">{`${
                appointment.client_name + "'s" + " " + appointment?.title
              }`}</del>
            </div>
          ) : (
            <div className="v-center div-4 fn-md">
              <span className="appt-title">{`${
                appointment.client_name + "'s" + " " + appointment?.title
              }`}</span>
            </div>
          )}
          <div className="address-information v-center fn-sm div-3">
            {!isTelehealth ? (
              <a className="cur-pointer" onClick={(event) => openMaps(event)}>
                {apptAddress}
              </a>
            ) : (
              <a
                className="cur-pointer"
                href={appointment?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {appointment?.link}
              </a>
            )}
          </div>
          <div className="v-center fn-sm div-3">
            <span>{`${
              appointment?.provider_name?.replace(/,/g, "") ?? ""
            }`}</span>
          </div>
        </div>
        {patientClinic?.includes(appointment?.home_clinic_id) &&
        !clientAppointmentSyncing(appointment?.crStatus) &&
        !clientAppointmentSyncing(appointment?.athenaStatus) &&
        !appointment?.scheduledStatus.includes(EVENT_STATUS.canceled) &&
        !cancel_success &&
        !cancellingAppointment ? (
          !isFailed ? (
            <div className="stack-4">
              <img className="dots-img" src={Warning} alt="warning" />
            </div>
          ) : !displayBanner ? (
            <div className={displayBanner ? "stack-4 downtime" : "stack-4"}>
              <img
                className="dots-img"
                src={dots}
                alt="dots"
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="popover-menu-clientAppointement-Bar">
                  <span
                    id="Reschedule-appt-initiation"
                    // className={withInDay ? "disabled-option" : ""}
                    onClick={handleRescheduleNavigation}
                  >
                    Reschedule Appointment{" "}
                  </span>
                </div>
                <div className="popover-menu-clientAppointement-Bar">
                  <span
                    // className={withInDay ? "disabled-option" : ""}
                    id="Cancel-appt-initiation"
                    onClick={handleCancelnavigation}
                  >
                    Cancel Appointment
                  </span>
                </div>
              </Popover>
            </div>
          ) : null
        ) : !isFailed ? (
          <div className="stack-4">
            <img className="dots-img" src={Warning} alt="warning" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(ClientAppointmentBar);
