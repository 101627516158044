import React, { useContext } from "react";
import "./clientRescheduleLate.scss";
import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";

interface LocationState {
  activeDay: string;
  path: string;
}

const ClientRescheduleLate: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { setPrefDate } = useContext(myContext);

  const { activeDay, path } = location.state;
  const HandleHomeBack = () => {
    setPrefDate(activeDay);
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };
  return (
    <div id="Reschedule-late" className="client-reschedule-late-container">
      <div className="client-reschedule-late-inner-container">
        <span className="reschedule-late-heading">
          Oops! It’s too late to reschedule!
        </span>

        <span className="reschedule-late-paragraph">
          We require at least a 24 hour notice in order to reschedule an
          appointment.
        </span>
        <span className="reschedule-late-paragraph">
          Please reach out to the{" "}
          <a
            className="reschedule-late-tag"
            href="mailto:customercare@corticacare.com"
          >
            Customer Care
          </a>{" "}
          team at{" "}
          <a className="reschedule-late-tag" href="tel:8888855068">
            888-885-5068
          </a>
          , option 2 from 4:30 am-6:00 pm PST for help.{" "}
        </span>
        <div>
          <Button
            className="reschedule-late-home-button"
            size="middle"
            onClick={HandleHomeBack}
          >
            {path === "appointments" ? "Home" : "All Appointments"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientRescheduleLate;
