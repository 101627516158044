import styled from "styled-components";

export const CustomAppointmentWrapper = styled.div`

.error-block {
  width: 100%;
  text-align: center;
  p {
    color: red;
    font-size: 16px;
  }
}
.time-style {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 40px !important;
  .MuiInputBase-root {
    height: 40px !important;
  }
  input {
    outline: none !important;
    border: none !important;
    text-transform: lowercase;
  }
  input[type="time"]::-webkit-clear-button {
    display: none !important;
  }
}

.ant-select {
  height: 40px !important;
}
.d-none {
  display: none !important;
}

.no-appointments {
  margin-top: 50px;
}

.ant-picker {
  height: 40px !important;
}
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  .wrapper-container {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
      margin-top: 45px
  }
  .span-block {
    display: flex;
    padding: 7px 5px;
    justify-content: center;
    width: 100%;
  }
  .confirm-button {
    margin-top: 30px;
  }
  .form-enclosure {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  .back-div {
    width: 100%;
    font-size: 18px;
    span {
      margin-left: 5px;
    }
  }
  .main-form {
    padding-top: 45px;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    padding: 15px 2px;
    width: 100%;
  }
  .form-item {
    width: 100%;
    padding: 5px;
    input {
      width: 100% !important;
    }
    div {
      width: 100% !important;
    }
  }
  .w-100 {
    width: 100%
  }
  .d-flex {
    display: flex;
  }

  .form-row {
    margin-top: 10px;
    display: flex;
    width: 100%;
    .form-row-item {
      width: 49%;
      display: flex;
      justify-content: center;
    }
  }
  .v-center {
    display: flex;
    align-items: center;
  }
  .h-center {
    display: flex;
    justify-content: center;
  }
  .submit-button {
    margin-top: 30px;
    width: 100%;
    text-align: center;

    button {
      color: rgba(16, 56, 154, 1);
      width: 150px;
      border: 1px solid rgba(16, 56, 154, 1);
      border-radius: 7px;
      height: 40px;
      background: transparent;
    }
    button:hover {
      border: 1px solid black;
      color: #ffffff;
      background-color: rgba(16, 56, 154, 1);
    }
    button:disabled {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: transparent !important;
    }
    button:disabled:hover {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: none !important;
    }
  }

  .header-div {
    padding: 5px 0px;
    width: fit-content;
    display: flex;
    .back-to {
      width: 50px;
      padding-right: 20px;
      button {
        font-size: 22px !important;
        color: rgba(16, 56, 154, 1) !important;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
    .page-desc {
      flex-grow: 1;
      font-size: 22px;
    }
  }
  // .css-dev-only-do-not-override-pr0fja {
  //   width: 100% !important;
  // }
  .ant-col {
    width: 100% !important;
    max-width: 150px;
    min-height: 95px;
    border-color: rgba(16, 56, 154, 1);
  }
  .ant-col-100 .ant-col {
    max-width: 385px;
    margin-left: auto;
    margin-right: auto;
    input {
      border: 1px solid rgba(16, 56, 154, 1) !important;
    }
  }
  .txt-area textarea {
    padding: 5px !important;
  }
  .ant-picker {
    width: 100%;
    max-width: 150px !important;
    border: 1px solid rgba(16, 56, 154, 1);
    padding-left: 12px;
    padding-right: 5px;
    border-radius: 6px;
}
.ant-select {
  border: 1px solid rgba(16, 56, 154, 1);
  border-radius: 6px;
}
.h-end {
  display: flex;
  justify-content: flex-end;
}
  }
  .time-style {
    width: 100% !important;
    border: 1px solid rgba(16, 56, 154, 1) !important;
    padding-left: 12px !important;
    padding-right: 5px !important;
    border-radius: 6px !important;
  }
  . @media only screen and (max-width: 425px) {
    .main-form {
      border: 1px solid yellow;
      padding: 5px;
    }
    .back-to {
      width: 50px;
    }
  }

  . @media only screen and (max-width: 375px) {
    .main-form {
      border: 1px solid yellow;
      padding: 5px;
    }
    .wrapper-container {
      margin-top: 25px
    }
  }
  .error-time-wrapper {
    transition-property: height;
    text-align: center;
  }
  .spinning-div {
    border: 3px solid #BEBEBE;
    border-radius: 50%;
    border-top: 3px solid #2997ff;
    width: 18px;
    height: 18px;
    animation: spin 0.7s linear infinite;
    display: inline-block
  }
  .gutter-top-50 {
    margin-top: 50px;
  }
  .gutter-top-20 {
    margin-top: 20px;
  }
  .fx-column{
    display: flex;
    flex-direction: column;
  }
  .loader-V2 {
    position: static !important;
  }
  .loader-wrapper-V2 {
    background: transparent !important;
    img {
      width: 60px !important;
    }
  }
  .loading-io {
    
  }
  .max-span {
    .ant-col {
      width: 100% !important;
      max-width: 400px !important;
    }
  }
  .txt-area {
    max-width: 375px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .antd-100 > .ant-col {
    width: 100%!important;
    max-width: 375px!important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
    text-transform: lowercase !important;
  }
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item {
    text-transform: lowercase !important;
  }
`;

export const ConfirmAppointmentWrapper = styled.div`
  width: 100%;
  .pad-8 {
    padding-right: 12px !important;
  }
  .txt-center {
    text-align: center;
  }
  .margin-r-2 {
    margin-right: 5px;
  }
  .span-block > span {
    color: rgba(18, 47, 78, 1);
    font-weight: 300;
    font-family: sans-serif;
  }
  .icon-span {
    padding-right: 8px;
  }

  . @media only screen and (max-width: 375px) {
    .wrapper-container {
      margin-top: 25px;
    }
  }
  .bolder-custom-span {
    font-weight: 600 !important;
  }
  .txt-center {
    text-align: center !important;
  }
  .error-block {
    width: 100%;
    text-align: center;
    p {
      color: red;
      font-size: 16px;
    }
  }
`;

export const ReviewProviderDayWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .attestation {
    font-size: 16px;
    width: 100%;
    max-width: 600px;
    display: flex;
    margin-top: 80px;
    span {
      margin-top: 1px;
    }
  }
  .d-contents {
    display: contents;
    max-width: 600px !important;
  }
  .caAttestation {
    font-size: 14px;
    width: 100%;
    max-width: 600px;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    margin-top: 40px;
  }
  .padding-lr-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .check-wrapper {
    width: 45px;
    display: flex;
    align-items: center;
    input {
      height: 16px;
      width: 16px;
    }
  }
  .text-wrapper {
    padding-left: 7px;
    flex-grow: 1;
    color: rgba(18, 47, 78, 1);
    margin-bottom: 20px;
    span {
      display: block;
      font-size: 14px;
      word-spacing: 1.5px;
      line-height: 1.3;
    }
  }
  .expand-animation {
    transition: height 0.3s ease-in-out;
  }

  .expand-animation.expand {
    height: auto;
  }
  .radio-wrapper {
    margin-bottom: 15px;
    input {
      margin-right: 15px;
    }
  }
  .m-bottom-20 {
    margin-bottom: 20px;
  }
  .submit-button {
    margin-top: 30px;
    width: 100%;
    text-align: center;

    button {
      color: rgba(16, 56, 154, 1);
      width: 150px;
      border: 1px solid rgba(16, 56, 154, 1);
      border-radius: 7px;
      height: 40px;
      background-color: tansparent;
      cursor: pointer;
    }
    button:hover {
      border: 1px solid black;
      color: #ffffff;
      background-color: rgba(16, 56, 154, 1);
    }
    button:disabled {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: transparent !important;
    }
    button:disabled:hover {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: none !important;
    }
  }
  .d-flex {
    display: flex;
  }
  .meal-warning {
    color: rgba(182, 0, 0, 1) !important;
    transition: height 0.3s ease;
  }

  .fixed-width-25 {
    width: 60px;
  }
  .flx-grow-1 {
    flex-grow: 1;
  }
  .d-block {
    display: block;
  }
  .warning-msg {
    padding-top: 2px;
    span {
      font-size: 12px;
      padding-bottom: 7px;
    }
  }

  .header-div {
    padding: 5px 0px;
    width: 100%;
    display: flex;
    .back-to {
      width: 50px;
      padding-right: 20px;
      button {
        font-size: 22px !important;
        color: rgba(16, 56, 154, 1) !important;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
    .page-desc {
      flex-grow: 1;
      font-size: 22px;
    }
  }
`;

export const EditAppointmentWrapper = styled.div`

.time-style {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 40px !important;
  .MuiInputBase-root {
    height: 40px !important;
  }
  
  input {
    outline: none !important;
    border: none !important;
    text-transform: lowercase;
  }
}
.d-none {
  display: none !important;
}
.ant-select {
  height: 40px !important;
}
.edit-appt-wrap{
  max-width: 375px;
  width: 100%;
  margin-right: auto;
  padding-bottom: 25px;
  span {
    font-size: 18px;
    font-weight: bolder;
  }
}
.edit-appt-details{
  max-width: 358px;
  width: 100%;
  margin-right: auto;
  padding-bottom: 25px;
  span {
font-family: "Helvetica Neue";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
}




  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  .wrapper-container {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
      margin-top: 45px
  }
  .span-block {
    display: flex;
    padding: 7px 5px;
    justify-content: center;
    width: 100%;
  }
  .confirm-button {
    margin-top: 30px;
  }
  .form-enclosure {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  .back-div {
    width: 100%;
    font-size: 18px;
    span {
      margin-left: 5px;
    }
  }
  .main-form {
    padding-top: 45px;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    padding: 15px 5px;
    width: 100%;
  }
  .form-item {
    width: 100%;
    padding: 5px;
    input {
      width: 100% !important;
    }
    div {
      width: 100% !important;
    }
  }
  .w-100 {
    width: 100%
  }
  .d-flex {
    display: flex;
  }

  .form-row {
    margin-top: 10px;
    display: flex;
    width: 100%;
    .form-row-item {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
  .v-center {
    display: flex;
    align-items: center;
  }
  .h-center {
    display: flex;
    justify-content: center;
  }
  .submit-button {
    margin-top: 30px;
    width: 100%;
    text-align: center;

    button {
      color: rgba(16, 56, 154, 1);
      width: 150px;
      border: 1px solid rgba(16, 56, 154, 1);
      border-radius: 7px;
      height: 40px;
      background: transparent;
    }
    button:hover {
      border: 1px solid black;
      color: #ffffff;
      background-color: rgba(16, 56, 154, 1);
    }
    button:disabled {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: transparent !important;
    }
    button:disabled:hover {
      border-color: rgba(128, 128, 128, 1) !important;
      color: rgba(128, 128, 128, 1) !important;
      background: none !important;
    }
  }

  .header-div {
    padding: 5px 0px;
    width: fit-content;
    display: flex;
    .back-to {
      width: 50px;
      padding-right: 20px;
      button {
        font-size: 22px !important;
        color: rgba(16, 56, 154, 1) !important;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
    .page-desc {
      flex-grow: 1;
      font-size: 22px;
    }
  }

  

  .ant-col {
    width: 100% !important;
    max-width: 150px;
    min-height: 95px;
    border-color: rgba(16, 56, 154, 1);
  }
  .ant-picker {
    width: 100%;
    max-width: 150px !important;
    border: 1px solid rgba(16, 56, 154, 1);
    padding-left: 12px;
    padding-right: 5px;
    border-radius: 6px;
}
.ant-select {
  border: 1px solid rgba(16, 56, 154, 1);
  border-radius: 6px;
}
.h-end {
  display: flex;
  justify-content: flex-end;
}

.antd-100 > .ant-col {
  width: 100%!important;
  max-width: 375px!important;
  // margin-left: auto !important;
  // margin-right: auto !important;
}

.reason-100 > .ant-col{
  width: 100% !important;
  max-width: 330px !important;
}

  }
  .time-style {
    width: 100% !important;
    border: 1px solid rgba(16, 56, 154, 1) !important;
    padding-left: 12px !important;
    padding-right: 5px !important;
    border-radius: 6px !important;
  }
  . @media only screen and (max-width: 425px) {
    .main-form {
      border: 1px solid yellow;
      padding: 5px;
    }
    .back-to {
      width: 50px;
    }
  }
  .ant-col-100 .ant-col {
    max-width: 377px;
    margin-left: auto;
    margin-right: auto;
    input {
      border: 1px solid rgba(16, 56, 154, 1) !important;
    }
  }
  .note-field {
    margin-right: auto;
    max-width: 377px !important;
  }
  .txt-area textarea {
    padding: 5px !important;
  }

  . @media only screen and (max-width: 375px) {
    .main-form {
      border: 1px solid yellow;
      padding: 5px;
    }
    .wrapper-container {
      margin-top: 25px
    }
  }
  .error-time-wrapper {
    transition-property: height;
    text-align: center;
  }
  .spinning-div {
    border: 3px solid #BEBEBE;
    border-radius: 50%;
    border-top: 3px solid #2997ff;
    width: 18px;
    height: 18px;
    animation: spin 0.7s linear infinite;
    display: inline-block
  }
  .gutter-top-50 {
    margin-top: 50px;
  }
  .gutter-top-20 {
    margin-top: 20px;
  }
  .fx-column{
    display: flex;
    flex-direction: column;
  }
  .loader-V2 {
    position: static !important;
  }
  .loader-wrapper-V2 {
    background: transparent !important;
    img {
      width: 60px !important;
    }
  }
  .loading-io {
    
  }
  .max-span {
    .ant-col {
      width: 100% !important;
      max-width: 400px !important;
    }
  }
  .h-center{
    display: flex;
    justify-content: center !important;
  }
`;

export const DriveTimeWrapper = styled.div`
  position: relative;
  .all-center-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-col {
    input {
      border: 1px solid rgba(16, 56, 154, 1) !important;
    }
  }
  .drt-wrapper {
    max-width: 385px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .extend-antcol {
    .ant-col {
      max-width: 385px;
      min-height: 50px;
    }
  }
  .d-none {
    display: none !important;
  }
  .reimburse-mileage-text {
    font-weight: 600;
  }
  .driveTime_cls .ui.grid > .row {
    padding: 10px 0 10px 0 !important;
    z-index: 0;
  }
  .optional {
    display: none;
  }
  .ant-col > div {
    margin-bottom: 10px;
  }
  .select-reason {
    margin: auto;
  }
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.checkbox input:focus ~ .box:before,
  .ui.checkbox input:focus ~ label:before {
    background-color: #e82424 !important;
  }
  .mileage_loader {
    position: absolute;
    top: 60%;
    left: 85%;
  }
  .error-message {
    color: #cc3f3f;
  }
  .search-by-client-selected {
    height: 32px;
    width: 363px;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #6f42f5;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .search-by-client {
    height: 32px;
    width: 100%;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .client-Provider_wrapper {
    height: 32px;
    position: relative;
    .rbt-input {
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
      &.focus {
        border-radius: 4px;
        border-color: #6f42f5;
        outline: none;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #415766;
      }
    }
    .rbt-input-hint {
      display: none !important;
    }
  }
  .rbt-menu {
    border-radius: 4px;
    background: #fff;
    z-index: 999;
    max-height: 216px !important;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 2px 0;
    border-color: #6f42f5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    overflow-y: overlay !important;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .client-Provider_option {
      transition: all 0.1s ease-in-out;
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      &:hover {
        background-color: #f2f4f5;
      }
    }
    .client-Provider_results,
    .client-Provider_search {
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      cursor: default;
    }
    .client-Provider_loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 16px 7px 16px;
    }
  }
  .search-icon {
    position: absolute;
    z-index: 80;
    top: 44px;
    left: 10px;
  }
  .search-icon-provider {
    position: absolute;
    z-index: 80;
    top: 50px;
    left: 28px;
  }

  .Provider_Wrapper {
    margin-top: 10px;
  }

  .mileage-style {
    margin-top: 4px;
  }
  .spread-input .ant-col {
    width: 100% !important;
    max-width: 385px !important;
    height: unset;
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    font-size: 14px;
    color: #415766;
    resize: vertical;
    margin-left: auto;
    margin-right: auto;
    border: none;
    input {
      width: 100%;
      min-height: 40px !important;
      border-radius: 4px !important;
      padding-left: 4px;
    }
    a {
      display: flex;
      align-items: center;
      padding: 7px;
    }
    &::placeholder {
      color: #919699;
    }
    &:focus {
      border-color: #6f4bf1;
      box-shadow: none;
    }
  }
  .mileage-wrapper {
    display: inline-block;
  }
  .mileage-wrapper .mileage-box.error-border {
    border: 1px solid #cc3f3f;
  }
  .d-block {
    display: block;
  }
  .remove-flex {
    .form-row-item {
      display: inline-block;
      margin-bottom: 6px;
    }
  }

  .remove-padding {
    padding: 0;
    margin: 0;
  }
  .syntactically-awesome-margin-bottom {
    margin-bottom: 10px !important;
  }
  .mileage-box {
    height: 30px;
    width: fit-content;
    min-width: 70px;
    border: 1px solid #10389a;
    border-radius: 3px;
    margin-right: 5px;
    .time-style {
      width: 70px;
      height: 30px;
      border: none !important;
    }
    fieldset {
      border: none !important;
    }
    input {
      font-size: 18px;
      width: 85px;
      height: 30px;
      padding: 2px;
    }
    p {
      font-size: 18px;
      padding: 0px 7px;
    }
  }
  .syntactically-awesome-margin-top {
    margin-top: 20px !important;
  }
  .syntactically-awesome-margin-left {
    margin-left: 50px !important;
  }
  .label-span {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: -2px;
  }
`;
