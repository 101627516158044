import React from "react";
import alert from "assets/InfoOutlined.svg";
import "./clientCancelBar.scss";
import { appointments } from "model/calender/appointments";
import { useHistory } from "react-router-dom";
import { Cancel_Name } from "./staticCancelData";
import { Button } from "antd";

interface Props {
  rcSuggestions: any;
  activeDay: string;
  appointment: appointments;
  path: string;
  payload: any;
  errorMessage?: boolean;
}

const ClientCancelBar: React.FC<Props> = ({
  rcSuggestions,
  activeDay,
  appointment,
  path,
  payload,
  errorMessage,
}) => {
  const history = useHistory();

  console.log(
    rcSuggestions,
    activeDay,
    appointment,
    path,
    payload,
    "[ClientCancelBar] data"
  );

  const handleReschedule = () => {
    history.push({
      pathname: "/client-reschedule-appointment",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
        flow: Cancel_Name,
      },
    });
  };

  return (
    <div className="client-cancel-bar-container">
      <div className="client-cancel-bar-top-container">
        <img src={alert} alt="alert" />
        <span className="client-cancel-bar-text">
          We have <strong>{rcSuggestions?.length}</strong> rescheduling options
        </span>
      </div>
      <div className="client-cancel-bar-bottom-container">
        <Button
          disabled={errorMessage}
          type="primary"
          className="client-cancel-bar-go-btn"
          onClick={handleReschedule}
          id="Cancel-Reschedule-Banner"
        >
          Let's Go
        </Button>
      </div>
    </div>
  );
};

export default ClientCancelBar;
