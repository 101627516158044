import React, { useCallback, useContext } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Calendar, Select, Row, Col, Input } from "antd";
import { Button } from "antd";
import checkedFile from "assets/CheckFilled.svg";
import {
  ClientRescheduleDropdown,
  default_Dropdown,
} from "./staticRescheduleData";
import Spinner from "features/Appointments/Spinner/spinner";
import moment from "moment";
import { formatTime } from "./clientReschedule.helper";
import { myContext } from "features/Hooks/useContext";
import dayjs, { Dayjs } from "dayjs";
import { useHistory } from "react-router-dom";
import { appointments } from "model/calender/appointments";
import { Cancel_Name } from "../CancelAppointments/staticCancelData";

interface Props {
  rcSuggestions: any;
  selectedDate: any;
  earliestEnabledDate: Dayjs;
  clickedIndex: number;
  dropdown: any;
  activeDay: string;
  appointment: appointments;
  path: string;
  textAreaValue: string;
  payload: any;
  flow?: string;
  handleBackButton: () => void;
}

export const ClientRescheduleCalender: React.FC<Props> = ({
  rcSuggestions,
  selectedDate,
  earliestEnabledDate,
  clickedIndex,
  dropdown,
  activeDay,
  appointment,
  path,
  textAreaValue,
  payload,
  flow,
  handleBackButton,
}) => {
  console.log(
    rcSuggestions,
    selectedDate,
    earliestEnabledDate,
    clickedIndex,
    dropdown,
    activeDay,
    appointment,
    path,
    textAreaValue,
    handleBackButton,
    "[reschedule] calender component"
  );

  const { setState } = useContext(myContext);
  const { TextArea } = Input;
  const history = useHistory();

  const onPanelChange = (value: Dayjs, mode: string) => {
    if (mode === "month") {
      value = value.startOf("month");
    }
    console.log(value, mode, "[reschedule] value in onPanelChange");
  };

  const handleDateSelect = (value: any) => {
    const objData = rcSuggestions.find(
      (element: any) => element.date === value.format("MM/DD/YYYY")
    );
    setState((prevState: any) => ({
      ...prevState,
      selectedDate: objData,
      clickedIndex: -1,
    }));

    console.log(objData, "[reschedule] data1");
  };

  const disabledDate = (current: Dayjs) => {
    const dateString = current.format("MM/DD/YYYY");
    const dateData = rcSuggestions?.find(
      (item: any) => item.date === dateString
    );
    return !dateData;
  };

  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`, "[reschedule] selected dropdown");
    setState((prevState: any) => ({ ...prevState, dropdown: value }));
  };

  const handleTimeClick = useCallback(
    (
      index: number,
      startTime: string,
      endtime: string,
      event: React.MouseEvent<HTMLDivElement>
    ) => {
      setState((prevState: any) => ({
        ...prevState,
        clickedIndex: index,
        newStartTime: startTime,
        newEndTime: endtime,
      }));
      console.log(event.currentTarget.textContent, "[reschedule] date value");
      console.log(
        startTime,
        endtime,
        "[reschedule] clicked starttime and endTime"
      );
    },
    [setState]
  );

  const handleTextData = (value: string) => {
    console.log(value, "[reschedule] textArea");
    setState((prevState: any) => ({ ...prevState, textArea: value }));
  };

  console.log(flow, "[rescheduleCalender] flow");

  const handleNextButton = () => {
    history.push({
      pathname: "/client-reschedule-confirm",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
        flow: flow,
      },
    });
  };

  const ButtonDisable = clickedIndex === -1 || dropdown === default_Dropdown;
  return (
    <>
      {rcSuggestions ? (
        <div className="reschedule-body">
          <div className="rescheduling-calender-left">
            <div className="reschedule-calender-style">
              <Calendar
                fullscreen={false}
                defaultValue={
                  selectedDate?.date === undefined
                    ? earliestEnabledDate
                    : dayjs(selectedDate?.date) === earliestEnabledDate
                    ? earliestEnabledDate
                    : dayjs(selectedDate?.date)
                }
                headerRender={({ value, type, onChange }) => {
                  console.log(value, "[reschedule] year value");
                  const year = value.year();
                  const month = value.month();

                  const handlePrev = () => {
                    onChange(value.subtract(1, type));
                  };

                  const handleNext = () => {
                    onChange(value.add(1, type));
                  };

                  const monthsShort = moment.monthsShort(); // Get localized month names using moment

                  return (
                    <div style={{ padding: 10 }}>
                      <Row gutter={8} align="middle" justify="space-between">
                        <Col>
                          <LeftOutlined
                            style={{ cursor: "pointer" }}
                            onClick={handlePrev}
                          />
                        </Col>
                        <Row gutter={8} align="middle" justify="center">
                          <Col>
                            <Select
                              size="small"
                              className="dropdown-1"
                              popupMatchSelectWidth={false}
                              value={type === "month" ? month : year}
                              onChange={(val) => {
                                const newValue =
                                  type === "month"
                                    ? value.month(val)
                                    : value.year(val);
                                onChange(newValue);
                              }}
                            >
                              {type === "month"
                                ? monthsShort.map((month, index) => (
                                    <Select.Option key={index} value={index}>
                                      {month}
                                    </Select.Option>
                                  ))
                                : Array.from({ length: 20 }, (_, i) => (
                                    <Select.Option
                                      key={i}
                                      value={year - 10 + i}
                                    >
                                      {year - 10 + i}
                                    </Select.Option>
                                  ))}
                            </Select>
                          </Col>
                          <Col>
                            <Select
                              size="small"
                              value={year}
                              onChange={(value) => {
                                const newValue =
                                  value !== undefined ? value : year;
                                onChange(dayjs(`${newValue}`, "YYYY")); // Ensure newValue is a string and create Dayjs object
                              }}
                              style={{ width: 80 }}
                              className="dropdown-1"
                            >
                              {Array.from({ length: 20 }, (_, i) => (
                                <Select.Option key={i} value={year - 10 + i}>
                                  {year - 10 + i}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                        <Col>
                          <RightOutlined
                            style={{ cursor: "pointer" }}
                            onClick={handleNext}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                onPanelChange={onPanelChange}
                onSelect={handleDateSelect}
                disabledDate={disabledDate}
              />
            </div>
          </div>
          <div className="rescheduling-calender-right">
            <div className="rescheduling-calender-right-top">
              {selectedDate ? (
                <>
                  <span className="right-heading">Available Times</span>
                  <Row className="right-time">
                    {selectedDate?.times?.map((item: any, index: any) => {
                      const timeClass =
                        clickedIndex !== index && clickedIndex !== -1
                          ? "clicked-time"
                          : "";
                      const renderImage = clickedIndex === index;
                      const showTime = formatTime(
                        item.startTime,
                        selectedDate?.clientTimezone
                      );

                      const startTime = item.startTime;
                      const endTime = item.endTime;
                      return (
                        <Col
                          span={5}
                          className={`right-time-inner ${timeClass}`}
                          key={index}
                          onClick={(event) =>
                            handleTimeClick(index, startTime, endTime, event)
                          }
                        >
                          {renderImage && (
                            <img
                              src={checkedFile}
                              alt="checked"
                              className="checkedImg"
                            />
                          )}
                          <span className="inner-timming">{showTime}</span>
                        </Col>
                      );
                    })}
                  </Row>

                  <div className="right-dropdown">
                    <Select
                      // defaultValue="Select Reschedule Reason"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      options={ClientRescheduleDropdown}
                      value={dropdown}
                      id="Reschedule-Reason"
                    />
                  </div>
                  <div
                    className={dropdown === 5 ? "displayShow" : "displayNone"}
                  >
                    <TextArea
                      rows={4}
                      placeholder="Add note (Optional)"
                      onChange={(e) => handleTextData(e.target.value)}
                      value={textAreaValue}
                    />
                  </div>
                </>
              ) : (
                <span className="right-inactive-days">
                  Please click on the active days to view the Available Times
                </span>
              )}
            </div>
            <div className="right-button">
              <Button
                id="Reschedule-abandonment"
                type="default"
                size="large"
                onClick={() => handleBackButton()}
                className="button-1"
              >
                {flow === Cancel_Name ? "Cancel Instead" : "Back"}
              </Button>
              <Button
                id="Reschedule-selection"
                type="primary"
                size="large"
                className="button-next button-1"
                disabled={ButtonDisable}
                onClick={handleNextButton}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
          <span className="client-reschedule-spinner-sub-heading">
            {" "}
            Please wait for a few seconds. We are fetching new dates and timings
            for you.
          </span>
        </>
      )}
    </>
  );
};

export default ClientRescheduleCalender;
