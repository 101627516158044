import React, { useEffect, useState } from "react";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";
import { DriveTimeWrapper } from "./style";
import { AddressInterface } from "model/appointments";
import SpinnerV2 from "features/Appointments/spinnerV2/spinnerV2";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { nullAddressCheck } from "./appointments.helper";
import { NumberField } from "sharedComponents/reactHookComponents";
import Select from "sharedComponents/reactHookComponents/select";
import { useAuth0 } from "react-auth0-spa";
import axios from "axios";

interface DriveTimeMileageProps {
  initiateAddressLoader: any;
  fetchAddressSuggestions: any;
  startingAddressHandler: any;
  destinationAddressHandler: any;
  milesHandler: any;
  reimburseableMilesHandler: any;
  driveTimeHandle: any;
  isFirstLastDrive: any;
  setIsExceedingMaxMiles: any;
  fetchingStartAddress: boolean;
  fetchingEndAddress: boolean;
  startingAddressSuggestions: any;
  setStartingAddressSuggestions: any;
  destinationAddressSuggestions: any;
  setDestinationAddressSuggestions: any;
  fullStartingAddress?: AddressInterface;
  fullEndingAddress?: AddressInterface;
  setMileAgeCalculation: any;
  errors?: {
    startingAddress?: {
      message: string;
    };
    destinationAddress?: {
      message: string;
    };
    isFirstLastDrive?: {
      message: string;
    };
  };
}

const DriveTimeMileage: React.FC<DriveTimeMileageProps> = ({
  fetchAddressSuggestions,
  startingAddressHandler,
  destinationAddressHandler,
  milesHandler,
  reimburseableMilesHandler,
  isFirstLastDrive,
  driveTimeHandle,
  setIsExceedingMaxMiles,
  fetchingStartAddress,
  fetchingEndAddress,
  fullStartingAddress,
  fullEndingAddress,
  errors,
  startingAddressSuggestions,
  setStartingAddressSuggestions,
  destinationAddressSuggestions,
  setDestinationAddressSuggestions,
  setMileAgeCalculation,
}) => {
  const [mileage, setMileage] = useState<number | null>(null);
  const [isMileageVisible, setMileageVisibility] = useState<boolean>(false);
  const [fetchingMileage, setFetchingMileage] = useState<boolean>(false);
  const { getTokenSilently } = useAuth0();

  const methods = useFormContext();

  const options = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const miles = useWatch({
    control: methods.control,
    name: ["miles"],
  });
  const reimburseableMiles = useWatch({
    control: methods.control,
    name: ["reimburseableMiles"],
  });

  const calculateMileage = async () => {
    setFetchingMileage(true);

    setMileAgeCalculation(true);

    if (
      fullStartingAddress?.lat &&
      fullStartingAddress.lon &&
      fullEndingAddress?.lat &&
      fullEndingAddress.lon
    ) {
      const startAddressCordinates = `${fullStartingAddress.lat},${fullStartingAddress.lon}`;

      const endingAddressCordinates = `${fullEndingAddress.lat},${fullEndingAddress.lon}`;

      const accessToken = await getTokenSilently();

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        // Make the mileage calculation request to the backend API

        const apiUrl =
          process.env.REACT_APP_STAGE === "local"
            ? "http://localhost:8000/calculatemileage"
            : `${process.env.REACT_APP_BACKEND_API}/calculatemileage`;

        const querySearch = `${startAddressCordinates}:${endingAddressCordinates}`;

        const response = await axios.post(
          apiUrl,

          {
            query: querySearch,
          },

          config
        );

        // Assuming the response structure, you may need to adjust based on the actual API response

        const mileageValue = response.data;

        setMileage(mileageValue);

        const mileageValueNumber = Number(mileageValue);

        const formattedValue =
          mileageValueNumber === 0
            ? "0"
            : Number(mileageValueNumber.toFixed(2));

        methods.setValue("miles", formattedValue);

        setFetchingMileage(false);

        setMileageVisibility(true);

        setMileAgeCalculation(false);
      } catch (error) {
        console.error("[DriveTimeMileage] calculating mileage error", error);

        setFetchingMileage(false);

        setMileageVisibility(false);

        setMileAgeCalculation(false);
      }
    }
  };

  const calculateReimbursableMileage = () => {
    if (isFirstLastDrive && mileage !== null) {
      let reimbursableMileage = Number(miles) - 15;
      reimbursableMileage = Math.max(reimbursableMileage, 0); // Ensure reimbursableMileage is not negative
      reimbursableMileage = Number(reimbursableMileage.toFixed(2)); // Remove decimal and trailing zero if it's exactly .0

      reimburseableMilesHandler(reimbursableMileage);
    }
    return null;
  };

  useEffect(() => {
    if (isFirstLastDrive) {
      calculateReimbursableMileage();
    } else {
      reimburseableMilesHandler(miles);
    }
  }, [isFirstLastDrive, miles]);

  useEffect(() => {
    // Automatically trigger calculateMileage when starting or destination address changes

    if (fullStartingAddress && fullEndingAddress) {
      if (nullAddressCheck(fullStartingAddress)) {
        if (nullAddressCheck(fullEndingAddress)) {
          calculateMileage();
        }
      }
    }
  }, [fullStartingAddress, fullEndingAddress]);
  const mileageValue = miles.length > 0 ? miles[0] : 0;
  if (mileageValue > 50) setIsExceedingMaxMiles(true);
  else setIsExceedingMaxMiles(false);
  return (
    <DriveTimeWrapper>
      <div className="drt-wrapper">
        <div className="form-row spread-input">
          <FormItem optional={false} label="Start Destination">
            <AsyncTypeahead
              disabled={fetchingStartAddress}
              promptText={<p>Type to search...</p>}
              placeholder={
                fetchingStartAddress
                  ? "Fetching starting address..."
                  : "Starting Address"
              }
              filterBy={() => true}
              delay={500}
              useCache={false}
              id="startingAddress"
              defaultSelected={
                fullStartingAddress?.freeformAddress
                  ? [fullStartingAddress.freeformAddress]
                  : []
              }
              labelKey="freeformAddress"
              minLength={3}
              isLoading={false}
              onChange={(selected: any[]) => {
                const selectedValue = selected.length > 0 ? selected[0] : "";
                startingAddressHandler(selectedValue);
              }}
              onSearch={(query: string) => {
                fetchAddressSuggestions(query).then((suggestions: any) => {
                  setStartingAddressSuggestions(suggestions);
                });
              }}
              options={startingAddressSuggestions}
              selected={
                fullStartingAddress?.freeformAddress
                  ? [{ freeformAddress: fullStartingAddress.freeformAddress }]
                  : []
              }
              searchText={<p>Loading Addresses...</p>}
            />
            {errors?.startingAddress?.message && (
              <span style={{ color: "#cc3f3f" }}>
                {errors.startingAddress.message.toString()}
              </span>
            )}
          </FormItem>
        </div>

        <div className="form-row spread-input">
          <FormItem optional={false} label="End Destination">
            <AsyncTypeahead
              disabled={fetchingEndAddress}
              placeholder={
                fetchingEndAddress
                  ? "Fetching destination address..."
                  : "Destination Address"
              }
              filterBy={() => true}
              useCache={false}
              delay={500}
              id="destinationAddress"
              defaultSelected={
                fullEndingAddress?.freeformAddress
                  ? [fullEndingAddress.freeformAddress]
                  : []
              }
              labelKey="freeformAddress"
              minLength={3}
              isLoading={false}
              onChange={(selected: any[]) => {
                const selectedValue = selected.length > 0 ? selected[0] : "";
                destinationAddressHandler(selectedValue);
              }}
              onSearch={(query: string) => {
                const formattedQuery = query.trim();
                fetchAddressSuggestions(formattedQuery).then(
                  (suggestions: any) => {
                    setDestinationAddressSuggestions(suggestions);
                  }
                );
              }}
              options={destinationAddressSuggestions}
              // selected={[{name: methods.getValues("destinationAddress")}]}
              selected={
                fullEndingAddress?.freeformAddress
                  ? [{ freeformAddress: fullEndingAddress.freeformAddress }]
                  : []
              }
              searchText={<p>Loading Addresses...</p>}
            />
            {errors?.destinationAddress?.message && (
              <span style={{ color: "#cc3f3f" }}>
                {errors.destinationAddress.message.toString()}
              </span>
            )}
          </FormItem>
        </div>

        {fetchingMileage && (
          <div className="loading-io w-100 d-flex fx-column gutter-top-50">
            <div className="load-div w-100 d-flex h-center">
              <SpinnerV2 />
            </div>
            <div className="title-div h-center gutter-top-20">
              <span>Calculating Mileage Data</span>
            </div>
          </div>
        )}

        {isMileageVisible && !fetchingMileage && (
          <>
            <div className="form-row-item">
              <div className="form-row-item extend-antcol">
                <FormItem
                  optional={false}
                  label="Is this the first/last drive to/from a non-clinic location?"
                >
                  <FormControlLabel
                    className="select-reason"
                    control={
                      <Select
                        name="isFirstLastDrive"
                        options={options}
                        field=""
                        errors={errors?.isFirstLastDrive?.message}
                        onChange={(event: any) => driveTimeHandle(event)}
                        value={isFirstLastDrive}
                        label="Select reason"
                        placeholder="Select"
                      />
                    }
                    label=""
                  />
                </FormItem>
                {isFirstLastDrive === null &&
                  errors?.isFirstLastDrive?.message && (
                    <span style={{ color: "#cc3f3f" }}>
                      {errors.isFirstLastDrive.message.toString()}
                    </span>
                  )}
              </div>
            </div>

            <div className="form-row remove-flex syntactically-awesome-margin-top">
              <div className="form-row-item">
                <p className="d-block remove-padding syntactically-awesome-margin-bottom">
                  Mileage
                </p>
                <div className="mileage-wrapper">
                  <div
                    className={`mileage-box all-center-align ${
                      mileageValue > 50 ? "error-border" : ""
                    }`}
                  >
                    {/* <p>{miles}</p> */}
                    <Controller
                      name="miles"
                      control={methods.control}
                      render={({ field }) => (
                        <NumberField
                          className="time-style"
                          field={field}
                          errors={methods?.formState?.errors?.miles}
                          value={miles}
                          onChange={(event: any) => {
                            let value = event.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            milesHandler(value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <span>Miles</span>
              </div>

              <div className="form-row-item">
                <p className="d-block remove-padding syntactically-awesome-margin-bottom">
                  Reimbursable Mileage
                </p>
                <div className="mileage-wrapper">
                  <div className="mileage-box all-center-align ">
                    <p>{reimburseableMiles}</p>
                  </div>
                </div>
                <span>Miles</span>
              </div>
            </div>
            {mileageValue > 50 && (
              <span style={{ color: "#cc3f3f" }}>
                The max mileage that can be added to this appointment is 50
                miles. Please submit any additional mileage using the expense
                app.
              </span>
            )}
          </>
        )}
      </div>
    </DriveTimeWrapper>
  );
};

export default DriveTimeMileage;
