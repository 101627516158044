import React, { useContext, useEffect } from "react";
import "./patientAvailability.scss";
import Home from "../../../../assets/Breadcrumb.svg";
import Location from "assets/Step 1.svg";
import { Button } from "@mui/material";
import Availabiity from "../../Availability/Availability";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";
import { Radio } from "antd";
const PatientAvailabiity: React.FC<{}> = () => {
  const { status, setStatus, carePlan, validateUrl } = useContext(myContext);
  const history = useHistory();

  const handleButtonClick = (value: any) => {
    setStatus(value);
  };

  const handleNavigationhome = () => {
    history.push({
      pathname: "/appointments",
    });
  };

  const HandleNavigation = () => {
    history.push({
      pathname: "/appointments-availability-days",
    });
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  });
  return (
    <div className="primary-PatientAvailabiity-container">
      <div>
        <div className="Home-navigation">
          <div className="Navigation">
            <img
              className="Home-img"
              src={Home}
              alt="Loading"
              onClick={handleNavigationhome}
            />
          </div>
        </div>
        <Availabiity />
        <div className="secondary-PatientAvailabiity-container">
          <img className="Location-img" src={Location} alt="Loading" />
          <div className="primary-text">
            Pick the most convenient location for {carePlan[0]?.therapy}{" "}
            therapy. Don’t worry, you’ll be able to add more locations later.
          </div>
          <div className="third-container">
            <div className="Radio-Button">
              <Radio
                value="Inclinic"
                checked={status === 3}
                onChange={() => handleButtonClick(3)}
              >
                In Clinic
              </Radio>
            </div>
            <div className="Radio-Button">
              <Radio
                value="Inclinic"
                checked={status === 5}
                onChange={() => handleButtonClick(5)}
              >
                Home
              </Radio>
            </div>
          </div>
        </div>
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleNavigation}
          disabled={status === null ? true : false}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PatientAvailabiity;
