import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useAuth0 } from "react-auth0-spa";
// import axios from "axios";
import Loader from "features/Loader/Loader";
import Failure from "../Failure/Failure";
import ProviderCalendarView from "../Calendar/ProviderCalenderView";
import { Appointment } from "model/appointments.js";
import Message from "../../../sharedComponents/reactHookComponents/UXComponents/Message";
import { useHistory } from "react-router-dom";
import {
  checkIfItisToday,
  isIsoStringGreaterThan,
} from "./AppointmentForms/appointments.helper";
import {
  ADMIN_APPT_TYPES_LABELS,
  CR_SYNC_AND_FAILURE_STATUSES,
} from "../../../constant/BiPortalConstants";
import { useLocation } from "react-router-dom";
import Footer from "../../../sharedComponents/footer/Footer";
import { ConfirmAllApptCall } from "./AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "./AppointmentForms/AllAppointmentsApi";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import CrBannerError from "sharedComponents/Error/crBannerError";

interface LocationState {
  prefStartDate?: string;
  cancelledAppt?: number;
  hardCancellation?: number;
}

const ProviderAppointmets: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { prefStartDate } = location?.state || {};
  const { cancelledAppt } = location?.state || {};
  const { hardCancellation } = location?.state || {};

  const { webSocketData } = useWebSocket();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [displayMileageError, setDisplayMileageError] =
    useState<boolean>(false);

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData.athena !== true || webSocketData.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  useEffect(() => {
    // Reload the component when displayBanner changes
    setLoadingAppointments(true);
  }, [displayBanner]);

  const pollingTime = 15000;

  const { loading, user, getTokenSilently } = useAuth0();

  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const [pollCount, setPollCount] = useState<number>(0);

  const [freezePoll, setFreezPoll] = useState<boolean>(false);

  const [providerId, setProviderId] = useState<number>();
  const [providerName, setProviderName] = useState<string>();
  const [providerClinicState, setProviderClinicState] = useState<string>();
  const [hasDurationMoreThan5Hours, setHasDurationMoreThan5Hours] =
    useState<boolean>(true);
  const [shouldAskRestBreakQuestion, setShouldAskRestBreakQuestion] =
    useState<boolean>(true);
  const [providerEmail, setProviderEmail] = useState<string>();

  const [providerClinicId, setProviderClinicId] = useState<number>();

  const [backendError, setBackendError] = useState<boolean>(false);

  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
  const [dayConfirmed, setDayConfirmed] = useState<boolean>(false);

  const [loadingAppointments, setLoadingAppointments] =
    useState<boolean>(false);
  const [softloading, setsoftloading] = useState<boolean>(false);
  const [StartDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [displayConfirm, setConfirmDisplay] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>();
  const [shouldPoll, setShouldPoll] = useState<boolean>(true);
  const [resyncCrId, setResyncCrId] = useState<number>();
  const [hasDriveTimeMileage, setHasDriveTimeMileage] = useState<boolean>(true);
  const [resyncDisable, setResyncDisable] = React.useState<boolean>(false);
  const [resyncCount, setResyncCount] = React.useState<number>(0);

  useEffect(() => {
    if (appointments.length === 0 || freezePoll || backendError) {
      setConfirmDisplay(false);
    } else {
      setConfirmDisplay(true);
    }
  });

  useEffect(() => {
    const hasDriveTimeAppts = appointments.some(
      (appointment) =>
        appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime ||
        appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly
    );
    if (hasDriveTimeAppts) {
      const hasDriveTimeMileage = appointments.every((appointment) => {
        if (
          appointment.title === ADMIN_APPT_TYPES_LABELS.driveTime ||
          appointment.title === ADMIN_APPT_TYPES_LABELS.mileageOnly
        ) {
          return (
            appointment.driveTimeMileage !== null &&
            appointment.reimbursableMileage !== null
          );
        }
        // Non-Drive Time appointments
        return true;
      });

      setHasDriveTimeMileage(hasDriveTimeMileage);
    }

    setPollCount(pollCount + 1);
  }, [appointments]);

  const pollData = (poll: boolean, start: any, end: any, load: boolean) => {
    if (poll) {
      getAppointments(start, end, load);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (backendError) {
      return;
    } else if (pollCount > 40) {
      //keep 123 (30 min)
      setFreezPoll(true);
      return;
    } else {
      if (shouldPoll === true) {
        const interval = setInterval(() => {
          pollData(true, StartDate, endDate, false);
        }, pollingTime);

        return () => clearInterval(interval);
      } else return;
    }
  }, [backendError, StartDate, endDate, pollCount, shouldPoll]);

  const getLatestDate = (appointments: Appointment[]): Date | null => {
    if (appointments.length === 0) {
      return null;
    }
    const dateObjects = appointments.map(
      (appointment) => new Date(appointment.start)
    );

    const latestDate = new Date(
      Math.max(...dateObjects.map((date) => date.getTime()))
    );

    return latestDate;
  };

  useEffect(() => {
    let areAppointmentsImpure = false;

    appointments.map((appointment) => {
      if (
        appointment.crStatus &&
        CR_SYNC_AND_FAILURE_STATUSES.includes(appointment.crStatus)
      ) {
        areAppointmentsImpure = true;
      }
    });

    const lastApptTime = getLatestDate(appointments);

    let currentTimeGreaterThanLastApptTime = false;

    if (lastApptTime) {
      currentTimeGreaterThanLastApptTime = isIsoStringGreaterThan(
        new Date(),
        lastApptTime!
      )
        ? false
        : true;
    }

    if (StartDate && checkIfItisToday(StartDate)) {
      setDisableConfirm(true);
    } else if (areAppointmentsImpure) {
      setDisableConfirm(true);
    } else if (dayConfirmed) {
      setDisableConfirm(true);
    } else if (currentTimeGreaterThanLastApptTime) {
      setDisableConfirm(true);
    } else {
      setDisableConfirm(false);
    }
  }, [
    StartDate,
    appointments,
    dayConfirmed,
    loadingAppointments,
    hasDriveTimeMileage,
  ]);

  const filteredResyncAppointments: any = appointments.filter(
    (appointment) => appointment.id === resyncCrId
  );

  async function getAppointments(
    startDate: any,
    endDate: any,
    setLoading: boolean
  ) {
    setStartDate(startDate);
    setEndDate(endDate);
    setBackendError(false);
    setDisplayMileageError(false);

    const payload = {
      user_id: user.sub,
      isProvider: user["https://corticacare.com/app_metadata"].provider,
      startDate: startDate,
      endDate: endDate,
      email: user.email,
      providerName: user.name,
    };
    setsoftloading(setLoading);
    setLoadingAppointments(true);

    try {
      const response = await ConfirmAllApptCall(
        ApiCalls.ConfirmAllCall,
        payload,
        getTokenSilently
      );
      setResponseData(response);
      const results: any = [];
      results.push(
        ...response.data[0].appointments.map((appointment: Appointment) => ({
          ...appointment,
          title: appointment.type_name,
          start: appointment.startAt,
          end: appointment.endAt,
          client: appointment.client_name,
          provider: appointment.provider_name,
          isCancelling:
            cancelledAppt && cancelledAppt === appointment.id ? true : false,
          isHardCancelling:
            hardCancellation && hardCancellation === appointment.id
              ? true
              : false,
        }))
      );

      results.sort((a: any, b: any) => {
        return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
      });

      setProviderId(response.data[0].providerId);
      setProviderName(response.data[0].providerName);
      setProviderClinicId(response.data[0].providerClinicId);
      setProviderClinicState(response.data[0].providerClinicState);
      setHasDurationMoreThan5Hours(response.data[0].hasDurationMoreThan5Hours);
      setShouldAskRestBreakQuestion(
        response.data[0].shouldAskRestBreakQuestion
      );
      setProviderEmail(response.data[0].providerEmail);
      if (response.data[0].isConfirmed) {
        setDayConfirmed(response.data[0].isConfirmed);
      } else {
        setDayConfirmed(false);
      }
      setAppointments(results);
      setBackendError(false);
      setLoadingAppointments(false);
      setsoftloading(false);
    } catch (error) {
      setBackendError(true);
      setLoadingAppointments(false);
      setsoftloading(false);
    }
  }

  const handleDataChange = useCallback(
    (startStr: any, endStr: any) => {
      setHasDriveTimeMileage(true);
      getAppointments(startStr, endStr, true);
      setPollCount(0);
      history.replace({ pathname: history.location.pathname, state: {} });
    },
    [getAppointments, history]
  );

  useEffect(() => {
    setLoadingAppointments(true);
  }, []);

  if (loading || !user) {
    return <Loader />;
  }

  const getAppointmentsTable = (
    appointments: Appointment[],
    providerId: number,
    providerName: string,
    providerClinicId: number
  ) => {
    return (
      <ProviderCalendarView
        appointments={appointments}
        handleDataChange={handleDataChange}
        softloading={softloading}
        providerId={providerId}
        providerName={providerName}
        providerClinicId={providerClinicId}
        providerEmail={user.email}
        disableAddAppt={dayConfirmed}
        dayConfirmed={dayConfirmed}
        toDate={responseData?.startDate}
        prefDate={prefStartDate}
        getAppointments={getAppointments}
        startDateProps={StartDate}
        endDateProps={endDate}
        setShouldPoll={setShouldPoll}
        setResyncCrId={setResyncCrId}
        filteredResyncAppointments={filteredResyncAppointments}
        resyncDisable={resyncDisable}
        setResyncDisable={setResyncDisable}
        resyncCount={resyncCount}
        setResyncCount={setResyncCount}
        displayMileageError={displayMileageError}
      />
    );
  };

  const reloadAppointments = () => {
    handleDataChange(StartDate, endDate);
  };

  const isProviderDataAvailable = user ? true : false;

  const confirmSchedule = () => {
    if (!hasDriveTimeMileage) {
      setTimeout(() => {
        setDisplayMileageError(true);
      }, 0); // Show mileage error message if drive time mileage is missing

      return; // Return early to prevent further execution
    }
    history.push({
      pathname: "/confirm-schedule",
      state: {
        providerId: providerId,
        providerName: providerName,
        date: StartDate,
        providerEmail: providerEmail,
        providerClinicState: providerClinicState,
        hasDurationMoreThan5Hours: hasDurationMoreThan5Hours,
        shouldAskRestBreakQuestion: shouldAskRestBreakQuestion,
      },
    });
  };

  return (
    <>
      <div className="primary-wrapper fd-column">
        {displayBanner && (
          <div>
            <CrBannerError></CrBannerError>
          </div>
        )}
        {backendError ? (
          <Failure refresh={reloadAppointments} />
        ) : freezePoll ? (
          <>
            <Message setPollCount={setPollCount} setBoolean={setFreezPoll} />
          </>
        ) : (
          isProviderDataAvailable &&
          getAppointmentsTable(
            appointments,
            providerId!,
            providerName!,
            providerClinicId!
          )
        )}
        {displayConfirm && (
          <div className="confirm-day-wrapper h-center">
            {!softloading && (
              <div className="confirm-new">
                <button disabled={disableConfirm} onClick={confirmSchedule}>
                  Confirm All
                </button>
              </div>
            )}
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default React.memo(ProviderAppointmets);
