import { CustomSelectOption } from "../RescheduleAppointments/staticRescheduleData";

export const Cancel_Name = "Cancel";

export const Cancel_Reason = "Select Cancellation Reason";

export const ClientCancelDropdown: CustomSelectOption[] = [
  {
    value: 1,
    label: "Family Emergency",
    key: "canr19", //Provider Cancel: Notice
  },
  {
    value: 2,
    label: "Financial Constraint",
    key: "canr28", //Patient Cancel One Time: Sick
  },
  {
    value: 3,
    label: "Patient Sick",
    key: "canr28", //Patient Cancel One Time: Sick
  },
  {
    value: 4,
    label: "No Longer Interested",
    key: "canr29", //Patient Cancel: No longer Interested
  },
  {
    value: 5,
    label: "Schedule Conflict",
    key: "canr13", //Patient Cancel: One time
  },
  {
    value: 6,
    label: "Vacation",
    key: "canr14", //Patient Cancel: Vacation
  },
  {
    value: 7,
    label: "Other",
    key: "canr13", //Patient Cancel: One time
  },
];
