export const environment = process.env.REACT_APP_STAGE

export const ADMIN_APPOINTMENTS_ARRAY = [
  {
    id: "1",
    value: "Admin Direct",
    prod: { id: 1 },
    uat: { id: 157 },
    test: { id: 157 },
    dev: { id: 1 },
    type: 0,
  },
  {
    id: "2",
    value: "Admin Indirect",
    prod: { id: 2 },
    uat: { id: 158 },
    test: { id: 158 },
    dev: { id: 2 },
    type: 0,
  },
  {
    id: "3",
    value: "Drive Time",
    prod: { id: 3 },
    uat: { id: 159 },
    test: { id: 159 },
    dev: { id: 3 },
    type: 0,
  },
  {
    id: "4",
    value: "Lunch",
    prod: { id: 4 },
    uat: { id: 160 },
    test: { id: 160 },
    dev: { id: 4 },
    type: 0,
  },
  {
    id: "5",
    value: "Mileage Only",
    prod: { id: 455 },
    uat: { id: 364 },
    test: { id: 364 },
    dev: { id: 276 },
    type: 0,
  }
];

export const CANCELLATION_REASONS = [
  {
    value: "canr09",
    label: "Patient Cancel: <72 hr",
  },
  {
    value: "canr18",
    label: "Provider Cancel: <72 hr",
  },
  {
    value: "canr08",
    label: "Non-Cancellation: Schedule Change",
  },
  {
    value: "canr27",
    label: "Patient Cancel <72 hour: Sick",
  },
  {
    value: "canr11",
    label: "Patient Cancel: Late",
  },
  {
    value: "canr12",
    label: "Patient Cancel: No Show",
  },
];

export const RESCHEDULE_REASONS = [
  {
    value: "canr15",
    label: "Patient Reschedule: <72 hr",
  },
  {
    value: "canr20",
    label: "Provider Reschedule: <72 hr",
  },
  {
    value: "canr08",
    label: "Non-Cancellation: Schedule Change",
  },
];

export const ADMIN_APPT_TYPES_LABELS = {
  adminDirect: "Admin Direct",
  adminIndirect: "Admin Indirect",
  driveTime: "Drive Time",
  lunch: "Lunch",
  meeting: "Meeting",
  mileageOnly: "Mileage Only",
  pto: "PTO",
  unavailable: "Unavailable",
  callout: "CallOut",
};

export const LOADING_LABELS = {
  updateAppt: "Updating Appointment",
  createAppt: "Creating Appointment",
  cancelAppt: "Canceling Appointment" 
}

export const SCHOOL_APPTS = ["ABA at School", "ABA at School Sub Session"];

export const CR_SYNCING_CODES = [1, 2];

export const CR_CREATING_CODE = 1;

export const CR_UPDATING_CODE = 2;

export const CR_CANCELLING_CODE = [3, 4];

export const CR_FAILURE_CODES = [401, 402, 403];

export const CR_SUCCESS_CODES = [101, 102, 103];

// export const DRIVETIME_APPT_IDS = [3, 159, 276, 276, 364];

export const CR_SYNC_AND_FAILURE_STATUSES = [1, 2, 3, 4, 401, 402, 403];

export const getDriveTimeIds = () => {
  if(environment === "local"){
    return ADMIN_APPOINTMENTS_ARRAY
    .filter((appointment) => appointment.id === "3" || appointment.id === "5")
    .map((appointment) => appointment.dev.id);
  } else if(environment === "dev"){
    return ADMIN_APPOINTMENTS_ARRAY
    .filter((appointment) => appointment.id === "3" || appointment.id === "5")
    .map((appointment) => appointment.dev.id);
  }else if(environment === "test"){
    return ADMIN_APPOINTMENTS_ARRAY
    .filter((appointment) => appointment.id === "3" || appointment.id === "5")
    .map((appointment) => appointment.test.id);
  }
  else if(environment === "uat"){
    return ADMIN_APPOINTMENTS_ARRAY
    .filter((appointment) => appointment.id === "3" || appointment.id === "5")
    .map((appointment) => appointment.uat.id);
  }
  else{
    return ADMIN_APPOINTMENTS_ARRAY
    .filter((appointment) => appointment.id === "3" || appointment.id === "5")
    .map((appointment) => appointment.prod.id);
  }
}

export const DRIVETIME_APPT_IDS: number[] = getDriveTimeIds();


