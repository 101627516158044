import React, { useContext, useEffect } from "react";
import "./clientCancelDetails.scss";
import { useHistory, useLocation } from "react-router-dom";
import { appointments } from "model/calender/appointments";
import calender from "assets/CalendarBlank.svg";
import RescheduleMapPin from "assets/RescheduleMapPin.svg";
import RescheduleClock from "assets/RescheduleClock.svg";
import Rescheduleuser from "assets/RescheduleUser.svg";
import moment from "moment";
import { IAddress } from "model/appointments";
import { Button } from "antd";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "../RescheduleAppointments/staticRescheduleData";
import { Cancel_Name, Cancel_Reason } from "./staticCancelData";

interface LocationState {
  activeDay: string;
  appointment: appointments;
  path: string;
  payload: any;
}

interface ContextType {
  state: any;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  setCancelDropDown: React.Dispatch<React.SetStateAction<any>>;
}

const ClientCancelDetails: React.FC<{}> = () => {
  const location = useLocation<LocationState>();
  const { state, setState, setPrefDate, setCancelDropDown } =
    useContext<ContextType>(myContext);
  const { activeDay, appointment, path, payload } = location?.state;
  const { rcSuggestions } = state;
  const history = useHistory();

  useEffect(() => {
    setCancelDropDown(Cancel_Reason);
  }, [setCancelDropDown]);

  console.log(
    activeDay,
    appointment,
    path,
    payload,
    rcSuggestions,
    "[cancelDetails] location state Data"
  );

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === "Telehealth") {
      address = appointment?.link;
    } else {
      appointment.location_type === "In Clinic"
        ? (address = `${appointment.clinic.name} Clinic`)
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const AppointmentDetails: any = [
    {
      src: calender,
      alt: "calendar",
      text: moment(appointment?.start).format("dddd, MMMM D, YYYY"),
    },
    {
      src: RescheduleClock,
      alt: "clock",
      text: `${moment(appointment?.start).format("h:mm a")} - ${moment(
        appointment?.end
      ).format("h:mm a")}`,
    },
    {
      src: Rescheduleuser,
      alt: "User",
      text: `${appointment.provider.split(", ").join(" ")}, ${
        appointment.speciality
      }`,
    },
    {
      src: RescheduleMapPin,
      alt: "pin",
      text: address,
    },
  ];

  const handleReschedule = () => {
    history.push({
      pathname: "/client-reschedule-appointment",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
        flow: Cancel_Name,
      },
    });
  };

  const handleBackButton = () => {
    setPrefDate(appointment?.start);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    setCancelDropDown(Cancel_Reason);
    history.push({
      pathname: "/appointments",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
      },
    });
  };

  const handleCancelNext = () => {
    history.push({
      pathname: "/client-cancel-appointment",
      state: {
        rcSuggestions: rcSuggestions,
        appointment: appointment,
        activeDay: activeDay,
        path: path,
        payload: payload,
      },
    });
  };

  console.log(AppointmentDetails, "[cancelDetails] appointment Data");

  return (
    <div className="client-cancel-details-container">
      <div className="client-cancel-inner-top-container">
        <span className="cancel-details-heading">
          We get it, life happens!{" "}
          <span className="cancel-details-inner-heading">
            Let’s cancel your appointment
          </span>
        </span>
        <div
          className={
            rcSuggestions.length > 0
              ? "client-cancel-mobile-reschedule-box-container"
              : "client-cancel-mobile-no-rescheduling-options-box-container"
          }
        >
          <span className="client-cancel-mobile-txt">
            {rcSuggestions.length > 0
              ? "We believe that your child’s success begins with the consistency of treatment."
              : "There are no rescheduling options for the next 14 business days."}
          </span>
          {rcSuggestions.length > 0 && (
            <span className="client-cancel-mobile-txt-question">
              Would you want to reschedule instead?
            </span>
          )}
          <hr className="client-cancel-mobile-divider" />
          <div className="client-cancel-mobile-reschedule-box-bottom-container">
            <span className="client-cancel-mobile-bottom-txt">
              {rcSuggestions.length > 0 ? (
                <span className="client-cancel-mobile-bottom-txt">
                  We have{" "}
                  <h2 className="client-cancel-mobile-reschedule-number-txt">
                    {rcSuggestions.length}
                  </h2>{" "}
                  rescheduling options
                </span>
              ) : (
                <span className="client-cancel-mobile-bottom-txt">
                  For rescheduling after 14 days, call our Customer Care team at{" "}
                  <a
                    className="client-cancel-mobile-number"
                    href="tel:8888855068"
                  >
                    888-885-5068
                  </a>, option 2 from 4:30 am-6:00 pm PST. Outside of business hours,{" "}
                  <a
                    className="client-cancel-email"
                    href="mailto:customercare@corticacare.com"
                  >
                    email us{" "}
                  </a>
                  and we'll handle it first thing in the morning.
                </span>
              )}
            </span>
            {rcSuggestions.length > 0 && (
              <Button
                id="Cancel-Reschedule-Initiation"
                type="primary"
                size="large"
                className="client-cancel-mobile-btn"
                onClick={handleReschedule}
              >
                Let's Go!
              </Button>
            )}
          </div>
        </div>
        {/* )} */}

        <span className="cancel-details-paragraph">
          {rcSuggestions.length > 0 ? (
            <span className="client-details-paragraph-txt">
              If reschedule still doesn’t work, lets go ahead and cancel{" "}
              {appointment?.client}’s {appointment?.title}
            </span>
          ) : (
            <span className="client-details-paragraph-txt">
              Please confirm if you'd like to proceed with cancelling{" "}
              {appointment?.client}’s {appointment?.title} below.
            </span>
          )}
        </span>
        <div className="client-details-container">
          {AppointmentDetails.map((data: any) => {
            return (
              <div className="client-details-inner-container">
                <img src={data?.src} alt={data?.alt} />
                <div title={data?.text} className="client-details-inner">
                  {data?.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="cancel-details-buttons">
        <Button
          id="Cancel-abandonment"
          type="default"
          size="large"
          onClick={handleBackButton}
          className="button-all"
        >
          Back
        </Button>
        <Button
          id="Cancel-Selection"
          type="primary"
          size="large"
          className="button-next button-all"
          onClick={handleCancelNext}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ClientCancelDetails;
