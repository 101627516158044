import React, { useContext, useEffect } from "react";
import "./clientCancelled.scss";
import { Button } from "antd";
import { myContext } from "features/Hooks/useContext";
import { appointments } from "model/calender/appointments";
import { useHistory, useLocation } from "react-router-dom";
import { default_Dropdown } from "../RescheduleAppointments/staticRescheduleData";
import confirmRescheduled from "assets/RescheduledConfirm.svg";
import { Cancel_Reason } from "./staticCancelData";

interface LocationState {
  appointment: appointments;
  path: string;
}

interface ContextType {
  setState: React.Dispatch<React.SetStateAction<any>>;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  setValidateUrl: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelDropDown: React.Dispatch<React.SetStateAction<any>>;
  setCancellingAppt: React.Dispatch<React.SetStateAction<number>>;
}

const ClientCancelled: React.FC<{}> = () => {
  const history = useHistory();
  const {
    setState,
    setPrefDate,
    setValidateUrl,
    setCancelDropDown,
    setCancellingAppt,
  } = useContext<ContextType>(myContext);
  const location = useLocation<LocationState>();

  const { appointment, path } = location.state;

  const handleNavigateHome = () => {
    setPrefDate(appointment?.start);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    setCancelDropDown(Cancel_Reason);
    setCancellingAppt(appointment?.id);
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  useEffect(() => {
    setValidateUrl(true);
  }, [setValidateUrl]);

  return (
    <div className="client-cancelled-container">
      <div className="client-cancelled-inner-container-top">
        <span className="client-cancelled-heading">Appointment Cancelled</span>

        <img
          className="client-cancelled-image"
          src={confirmRescheduled}
          alt="confirmed cancelled"
        />
      </div>
      <div className="client-cancelled-inner-container-bottom">
        <span>
          Thank you for letting us know! You will be receiving a confirmation
          email shortly. The cancelled appointment is now reflected on the
          portal.
        </span>
        {/* <span>
          [You can schedule a make-up appointment now or you can always come
          back at any time.]
        </span> */}
        <div className="client-cancelled-button">
          <Button
            className="client-cancelled-home"
            onClick={handleNavigateHome}
          >
            {path === "appointments" ? "Home" : "All Appointments"}
          </Button>
          {/* <span className="client-cancelled-Make-Up">
            Schedule Make-Up Appointment
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default ClientCancelled;
