import { Appointment, CategorisedObservations } from "model/appointments";
import moment from "moment";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  // changeEventFrame,
  classifyObservations,
  formatObservations,
  getTimeFromStrings,
  getTimeUtcString,
  GetUpdateCoursePayload,
} from "./appointments.helper";
import { ConfirmAppointmentWrapper } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faLocationArrow,
  faUser,
  faCar,
  faArrowCircleRight,
  faBan,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { RESCHEDULE_REASONS } from "constant/BiPortalConstants";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import { AddressInterface } from "model/appointments";
import { APPT_TYPES } from "constant/AppConstants";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";
import { LOADING_LABELS } from "constant/BiPortalConstants";
import { editAappointment } from "./AllAppointmentsFunction";
import { useAuth0 } from "react-auth0-spa";

interface Props {
  isAdmin: boolean;
  appointment: Appointment;
  isDriveTime: boolean;
  startingAddress?: AddressInterface;
  destinationAddress?: AddressInterface;
  showReasons: boolean;
}

const ConfirmEdit: React.FC<Props> = ({
  isAdmin,
  appointment,
  isDriveTime,
  startingAddress,
  destinationAddress,
  showReasons,
}) => {
  const methods = useFormContext();
  const history = useHistory();
  const { user, getTokenSilently } = useAuth0();
  const [softloading, setsoftloading] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const isAba = appointment.appointment_type === APPT_TYPES.aba;

  console.log("[confirmedit] app log", appointment);

  const getExtractedMethodsData = () => {
    return {
      startDate: methods.getValues("startTime"),
      endDate: methods.getValues("endTime"),
      reason: methods.getValues("reason"),
      startingAddress: startingAddress,
      destinationAddress: destinationAddress,
      miles: methods.getValues("miles"),
      reimburseableMiles: methods.getValues("reimburseableMiles"),
      isFirstLastDrive: methods.getValues("isFirstLastDrive"),
      startReason: methods.getValues("startReason"),
      endReason: methods.getValues("endReason"),
      note: methods.getValues("note"),
    };
  };

  const formData = getExtractedMethodsData();

  const NewStartTime = getTimeUtcString(
    appointment.startAt,
    formData.startDate
  );
  const NewEndTime = getTimeUtcString(appointment.startAt, formData.endDate);

  console.log("[ConfirmEditOb] appt", appointment);

  // if(isAba && appointment.observations?.length){
  //   console.log("[ConfirmEditOb] appt modification", appointment);
  //   appointment = changeEventFrame(appointment, NewStartTime, NewEndTime)
  // }

  const observationEvents: CategorisedObservations | undefined = isAba
    ? classifyObservations(appointment, NewStartTime, NewEndTime)
    : undefined;

  let formattedObserverData;
  if (
    observationEvents?.canceledEvents.length ||
    observationEvents?.timeChangedEvents.length
  ) {
    formattedObserverData = formatObservations(observationEvents);
  }

  const reason1 = RESCHEDULE_REASONS.filter((reason) => {
    // return reason.value === cancelReason;
    return isAdmin
      ? reason.value === formData.reason
      : reason.value === formData.startReason;
  })[0];

  const reason2 = RESCHEDULE_REASONS.filter((reason) => {
    // return reason.value === cancelReason;
    return reason.value === formData.endReason;
  })[0];

  const displayDate = moment(new Date(appointment.start)).format(
    "MMMM DD, YYYY"
  );

  const note = methods.getValues("note");

  const startAddr = isDriveTime ? methods.getValues("startingAddress") : null;

  const destAddr = isDriveTime ? methods.getValues("destinationAddress") : null;

  const mileAge = methods.getValues("miles");

  const reimbursableMileage = methods.getValues("reimburseableMiles");

  const displayTime = getTimeFromStrings({
    selectedDate: appointment.start,
    startTime: formData.startDate,
    endTime: formData.endDate,
  });

  const homeRedirection = () => {
    if (
      (formData.startReason && formData.endReason) ||
      (appointment.appointment_type === APPT_TYPES.admin && showReasons)
    ) {
      history.push({
        pathname: "/providerappointments", // New route
        state: {
          prefStartDate: appointment.start,
          hardCancellation: appointment.id,
        },
      });
    } else if (
      appointment.appointment_type === APPT_TYPES.admin &&
      !showReasons
    ) {
      history.push({
        pathname: "/providerappointments", // New route
        state: {
          prefStartDate: appointment.start,
        },
      });
    } else {
      history.push({
        pathname: "/providerappointments", // New route
        state: {
          prefStartDate: appointment.start,
          cancelledAppt: appointment.id,
        },
      });
    }
  };

  const coursePayload = GetUpdateCoursePayload(
    appointment,
    isAdmin,
    user.email,
    formData,
    observationEvents
  );

  async function EditAppointment(payload: any) {
    setsoftloading(true);
    setBackendError(false);
    try {
      if (showReasons) {
        await editAappointment(
          ApiCalls.ConfirmEditCall,
          payload,
          getTokenSilently
        );
      } else {
        await editAappointment(
          ApiCalls.driveTimeEdit,
          payload,
          getTokenSilently
        );
      }
      setsoftloading(false);
      homeRedirection();
    } catch (error) {
      console.log("[EditAppt] [Provider] error", error);
      setsoftloading(false);
      setBackendError(true);
    }
  }

  const submitData = async () => {
    await EditAppointment(coursePayload);
  };

  const handleCloseErrorPopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: appointment.start,
        hardCancellation: appointment.id,
      },
    });
  };

  return (
    <ConfirmAppointmentWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleCloseErrorPopUp}
            heading={"Sorry, That didn't go well"}
            paragraph={
              "The edit appointment operation has failed due to server error. You can try again after few minutes. Meanwhile please check if the appointment has been edited/reschedule and admin-direct has got created as substitute"
            }
          />
        </>
      )}
      <div className="wrapper-container">
        {softloading && (
          <CorticaLoader message={LOADING_LABELS.updateAppt}></CorticaLoader>
        )}
        {!softloading && (
          <div className="info-content-div w-100">
            <div className="span-block">
              {isAdmin ? (
                <span>{appointment.title}</span>
              ) : (
                <>
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faUser} />
                  </span>
                  <span>
                    {appointment.client_name}, {appointment.title}
                  </span>
                </>
              )}
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faCalendar} />
              </span>
              <span>{displayDate}</span>
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faClock} />
              </span>
              <span>
                {displayTime.startTime.toLowerCase()} -{" "}
                {displayTime.endTime.toLowerCase()}
              </span>
            </div>
            {isAdmin ? (
              <></>
            ) : (
              <div className="span-block">
                <span className="icon-span">
                  <FontAwesomeIcon
                    className="icon-back"
                    icon={faLocationArrow}
                  />
                </span>
                {appointment.location_type !== "Telehealth" ? (
                  <span>
                    {appointment.address?.addressLine1}.{" "}
                    {appointment.address?.city}, {appointment.address?.state},{" "}
                    {appointment.address?.zipCode}
                  </span>
                ) : appointment.link !== "N/A" ? (
                  <span>{appointment.link}</span>
                ) : (
                  <span>{appointment.location_type}</span>
                )}
              </div>
            )}
            {reason1 && (
              <>
                <div className="span-block">
                  <span>
                    {isAdmin ? (
                      <span className="bolder-custom-span">Reason:</span>
                    ) : (
                      <span className="bolder-custom-span">
                        Reason for Start Time:
                      </span>
                    )}{" "}
                    {reason1?.label}
                  </span>
                </div>
              </>
            )}
            {reason2 && (
              <div className="span-block">
                <span>
                  <span className="bolder-custom-span">
                    Reason for End Time:
                  </span>{" "}
                  {reason2?.label}
                </span>
              </div>
            )}

            {isDriveTime && (
              <>
                <div className="span-block txt-center">
                  <span>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faLocationArrow}
                      />
                    </span>
                    <span className="bolder-custom-span margin-r-2">
                      Start Destination:
                    </span>
                    {startAddr}
                  </span>
                </div>
                <div className="span-block txt-center">
                  <span>
                    <span className="icon-span">
                      <FontAwesomeIcon
                        className="icon-back"
                        icon={faLocationArrow}
                      />
                    </span>
                    <span className="bolder-custom-span margin-r-2">
                      End Destination:
                    </span>
                    {destAddr}
                  </span>
                </div>
                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faCar} />
                  </span>
                  <span>
                    <span className="bolder-custom-span margin-r-2">
                      Mileage:
                    </span>
                    {mileAge ? mileAge : 0} miles
                  </span>
                </div>

                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faCar} />
                  </span>
                  <span>
                    <span className="bolder-custom-span margin-r-2">
                      Reimbursable Mileage:
                    </span>
                    {reimbursableMileage ? reimbursableMileage : 0} miles
                  </span>
                </div>
              </>
            )}
            {formattedObserverData && (
              <>
                <div className="span-block">
                  <span className="icon-span">
                    <FontAwesomeIcon
                      className="icon-back"
                      icon={faExclamationTriangle}
                    />
                  </span>
                  <span>
                    <span className="bolder-custom-span margin-r-2">
                      Observations Warning:
                    </span>
                    Some of the observation events are getting affected due to
                    change in start or end time of this event
                  </span>
                </div>
                {formattedObserverData?.rescheduled && (
                  <>
                    <div className="span-block">
                      <span className="icon-span">
                        <FontAwesomeIcon
                          className="icon-back"
                          icon={faArrowCircleRight}
                        />
                      </span>
                      <span>
                        <span className="bolder-custom-span margin-r-2">
                          Rescheduling Observation:
                        </span>
                        {formattedObserverData.rescheduled}
                      </span>
                    </div>
                  </>
                )}
                {formattedObserverData?.canceled && (
                  <>
                    <div className="span-block">
                      <span className="icon-span">
                        <FontAwesomeIcon className="icon-back" icon={faBan} />
                      </span>
                      <span>
                        <span className="bolder-custom-span margin-r-2">
                          Canceling Observations:
                        </span>
                        {formattedObserverData.canceled}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
            {note && (
              <div className="span-block">
                <span>
                  <span className="bolder-custom-span margin-r-2">Note:</span>
                  {note}
                </span>
              </div>
            )}
            <div className="submit-button">
              <button onClick={submitData}>Confirm</button>
            </div>
          </div>
        )}
      </div>
    </ConfirmAppointmentWrapper>
  );
};

export default React.memo(ConfirmEdit);
