import React from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import Loading from "features/Loader/Loader";
import Navbar from "features/Header/Navbar";
import Footer from "features/Footer/Footer";
import Routes from "./Routes";
import MainWebSocket from "./websocket";
import { shouldRenderFooterAndNavbar, shouldRenderHeader } from "./utils";
import "./App.sass";
import { MyContextProvider } from "features/Hooks/useContext";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";

function App() {
  const { loading } = useAuth0();
  const location = useLocation();
  MainWebSocket();

  if (loading) {
    return <CorticaLoader message="Cortica Telehealth" />;
  }

  return (
    <MyContextProvider>
      <div>
        <section className="App">
          {shouldRenderHeader(location.pathname) && (
            <div className="sticky-header">
              <Navbar />
            </div>
          )}
          <div
            className="body-content"
            style={{
              marginTop: shouldRenderHeader(location.pathname) ? "20px" : "0",
            }}
          >
            <Routes />
            {shouldRenderFooterAndNavbar(location.pathname) && <Footer />}
          </div>
        </section>
      </div>
    </MyContextProvider>
  );
}

export default App;
