import React from "react";
import CorticaSpinner from "assets/CorticaSpinner.png";
import "./CorticaLoader.scss";

type CorticaLoaderProps = {
  message?: string;
};

const CorticaLoader: React.FC<CorticaLoaderProps> = ({ message }) => {
  return (
    <div className="loader-wrapper-V3">
      <div className="loader-V3">
        <img className="spin-V3" src={CorticaSpinner} alt="spinner" />
        <p className="loader-message">
          {message ? message : "Cortica Telehealth"}
        </p>
      </div>
    </div>
  );
};

export default CorticaLoader;
