export const default_Dropdown = "Select Reschedule Reason";

export interface CustomSelectOption {
  key?: string;
  label?: string;
  value?: number;
}

export const ClientRescheduleDropdown: CustomSelectOption[] = [
  {
    value: 1,
    label: "Family Emergency",
    key: "canr19", //Provider Cancel: Notice
  },
  {
    value: 2,
    label: "Patient Sick",
    key: "canr28", //Patient Cancel One Time: Sick
  },
  {
    value: 3,
    label: "Schedule Conflict",
    key: "canr13", //Patient Cancel: One time
  },
  {
    value: 4,
    label: "Vacation",
    key: "canr14", //Patient Cancel: Vacation
  },
  {
    value: 5,
    label: "Other",
    key: "canr13", //Patient Cancel: One time
  },
];

export const Reschedule_Name = "Reschedule";

export const error_msg = "Request failed with status code 504";

export const error_504_status = 504;
