import { Typography } from "@mui/material";
import React from "react";
import "./errorMessage.css";
import Warning from "assets/WarningAmberOutlined.svg";
import moment from "moment";
interface props {
  from?: string;
  date?: string;
  syncStatus?: boolean;
  displayBanner?: boolean;
  BackEndError?: boolean;
  syncActionError?: boolean;
  syncActionMsg?: string;
  syncActionDate?: string;
}

const ClientRescheduleError: React.FC<props> = ({
  from,
  date,
  syncStatus,
  displayBanner,
  BackEndError,
  syncActionError,
  syncActionMsg,
  syncActionDate,
}) => {
  const startDate = moment(date).format("h:mm a");
  const syncActionDateFormat = moment(syncActionDate).format("h:mm a");
  console.log(from, "[cancel flow] from");

  const shouldDisplayErrorMessage =
    displayBanner ||
    BackEndError ||
    (syncStatus === true && from === "cancelFlow") ||
    (syncActionError && syncActionMsg === "cancel") ||
    (syncStatus === true && from === "rescheduleFlow") ||
    (syncActionError && syncActionMsg === "update");

  if (!shouldDisplayErrorMessage) {
    return null;
  }

  return (
    <div className="Client-reschedule-error-message-container">
      <div className="Client-reschedule-error-message">
        <img
          className="Client-reschedule-Warning-img"
          src={Warning}
          alt="Warning"
        />
        {displayBanner || BackEndError ? (
          <Typography>
            <p className="Client-reschedule-error-message-1">
              We’re temporarily unable to change your appointments through the
              portal right now. Please call our Customer Care team at
              888-885-5068, option 2 from 4:30 am-6:00 pm PST. Outside of
              business hours,{" "}
              <a
                className="client-email"
                href="mailto:customercare@corticacare.com "
              >
                email us
              </a>{" "}
              and we'll handle it first thing in the morning
            </p>
          </Typography>
        ) : (syncStatus === true && from === "cancelFlow") ||
          (syncActionError && syncActionMsg == "cancel") ? (
          <Typography>
            <p className="Client-reschedule-error-message-1">
              Due to a system error we could not cancel your appointment at{" "}
              {syncActionError && syncActionMsg === "cancel"
                ? syncActionDateFormat
                : startDate}
              . Please call our Customer Care team at 888-885-5068, option 2
              from 4:30 am-6:00 pm PST. Outside of business hours, email us and
              we’ll handle it first thing in the morning.
            </p>
          </Typography>
        ) : (syncStatus === true && from === "rescheduleFlow") ||
          (syncActionError && syncActionMsg == "update") ? (
          <Typography>
            <p className="Client-reschedule-error-message-1">
              Editing appointments online is currently unavailable due to a
              system error. Please call the Contact Center at 888-885-5068
              option 2
            </p>
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ClientRescheduleError);
