import { Dialog } from "@mui/material";
import { Button } from "antd";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { patientAvailabilityApptCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { myContext } from "features/Hooks/useContext";
import Loader from "features/Loader/Loader";
import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import X from "assets/X.svg";
import "./redirect-availability.scss";

export const RedirectAvailability = () => {
  const { getTokenSilently, user } = useAuth0();
  const { setCarePlan, setClientId } = useContext(myContext);
  const [errorApi, setErrorApi] = useState(false);
  const history = useHistory();

  useEffect(() => {
    HandleRedirectCall();
  }, []);

  const HandleRedirectCall = async () => {
    const payload = {
      email: user.email,
    };

    try {
      const response = await patientAvailabilityApptCall(
        ApiCalls.patientAvailability,
        payload,
        getTokenSilently
      );

      if (response?.data[0]?.clientDayAvailability <= 0) {
        setClientId(response?.data[2]?.clientId);
        setCarePlan(response?.data[1]);
        history.push({
          pathname: "/appointments-availability",
        });
      } else {
        setCarePlan(response?.data[1]);
        setClientId(response?.data[2]?.clientId);
        history.push({
          pathname: "/edit-appointments-availiability",
        });
      }
    } catch (err) {
      setErrorApi(true);
      console.error(
        "[HandleRedirectCall] [RedirectAvailability] Error in API call:",
        err
      );
    }
  };

  const handleDataClose = () => {
    setErrorApi(false);
  };

  const HandleReloadPage = () => {
    window.location.reload();
  };

  return (
    <div>
      {errorApi ? (
        <Dialog
          open={errorApi}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialogue-reload"
        >
          <div className="dialogue-reload-redirect-container">
            <div className="dialogue-X-img">
              <img src={X} alt="Loading" onClick={handleDataClose} />
            </div>
            <div className="dialogue-Heading">
              Something went wrong. Please try again in a moment.
            </div>
            <div className="button">
              <Button className="Warning-button" onClick={HandleReloadPage}>
                Try again
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default RedirectAvailability;
