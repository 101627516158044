import { appointments } from "model/calender/appointments";
import moment from "moment";
const momentTz = require("moment-timezone");

export function DiffMinute(startTime: string, endTime: string) {
  return moment.duration(moment(endTime).diff(moment(startTime))).asMinutes();
}

export function AddDays(startDate: string, days: number) {
  return moment(startDate).add(days, "days");
}

export function formatTime(timeString: string, zone: any) {
  return momentTz.tz(timeString, zone).format("hh:mm a");
}

export function startTimeAndendtime(startDate: string, endDate: string) {
  return `${moment(startDate).format("h:mm a")} - ${moment(endDate).format(
    "h:mm a"
  )}`;
}

export function rescheduledDate(startTime: string) {
  return moment(startTime).format("dddd MMMM D, YYYY");
}

export const formatAppointmentDateTime = (start: string, end: string) => {
  const formattedStartDate = moment(start).format("dddd MMMM D, YYYY");
  const formattedStartTime = moment(start).format("h:mm a");
  const formattedEndTime = moment(end).format("h:mm a");

  return `${formattedStartDate} at ${formattedStartTime} - ${formattedEndTime}`;
};

export const addressData = (appointment: appointments) => {
  return appointment?.location_type === "Telehealth"
    ? null
    : {
        addressLine1: appointment?.address?.addressLine1,
        addressLine2: appointment?.address?.addressLine2,
        city: appointment?.address?.city,
        country: appointment?.address?.country,
        name: appointment?.address?.name,
        state: appointment?.address?.state,
        zipCode: appointment?.address?.zipCode,
        id: appointment?.address?.id,
      };
};
