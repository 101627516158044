import React, { useContext, useEffect, useRef, useState } from "react";
import "./patientAvailabilityBuilderReview.scss";
import checkYourWork from "assets/Check-your-work.svg";
import { AvailabilityCard } from "features/Appointments/AvailiablilityCards/AvailiabilityCards";
import { Button } from "antd";
import { myContext } from "features/Hooks/useContext";
import { useHistory } from "react-router-dom";
import { DatePicker } from "antd";
import { dayOfWeek } from "./staticAvailabilityData";
import dayjs from "dayjs";
import { patientAvailabilityApptCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { TIMEZONE } from "constant/calenderView";
import { useAuth0 } from "react-auth0-spa";
import Dialog from "@mui/material/Dialog";
import X from "assets/X.svg";

interface AvailabilityCardRef {
  handleValidate: () => void;
}

interface YourItemType {
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  locationInput: number;
}

const PatientAvailabilityBuilderReview: React.FC<{}> = () => {
  const { getTokenSilently, user } = useAuth0();
  const {
    totalWeek,
    setTotalWeek,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thrusday,
    setThrusday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    availabilityDate,
    setAvailiabilityDate,
    clientId,
    validateUrl,
  } = useContext(myContext);
  const history = useHistory();
  const [emptyData, setEmptyData] = useState<boolean>();
  const [navigate, setNavigate] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const refMonday = useRef<AvailabilityCardRef | null>(null);
  const refTuesday = useRef<AvailabilityCardRef | null>(null);
  const refWednesday = useRef<AvailabilityCardRef | null>(null);
  const refThursday = useRef<AvailabilityCardRef | null>(null);
  const refFriday = useRef<AvailabilityCardRef | null>(null);
  const refSaturday = useRef<AvailabilityCardRef | null>(null);
  const today = new Date();

  const handleReviewsBack = () => {
    history.push({
      pathname: "/appointments-availability-review",
    });
  };

  const onChange = (e: any) => {
    setAvailiabilityDate(e);
  };

  useEffect(() => {
    setTotalWeek({
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thrusday: thrusday,
      friday: friday,
      saturday: saturday,
    });
  }, [monday, tuesday, wednesday, thrusday, friday, saturday, setTotalWeek]);

  const HandleReviewNavigation = async () => {
    setReload(false);
    setNavigate(true);
    const noDataForEveryDay = Object.values(totalWeek).every(
      (data: any) => data.length === 0
    );

    setEmptyData(noDataForEveryDay);

    refMonday.current?.handleValidate();
    refTuesday.current?.handleValidate();
    refWednesday.current?.handleValidate();
    refThursday.current?.handleValidate();
    refFriday.current?.handleValidate();
    refSaturday.current?.handleValidate();

    if (
      refMonday.current?.handleValidate() &&
      refTuesday.current?.handleValidate() &&
      refWednesday.current?.handleValidate() &&
      refThursday.current?.handleValidate() &&
      refFriday.current?.handleValidate() &&
      refSaturday.current?.handleValidate() &&
      noDataForEveryDay === false
    ) {
      const resultedData = Object.values(totalWeek).flatMap((data) =>
        (data as YourItemType[]).map(({ startTime, endTime, ...rest }) => ({
          ...rest,
          startTime: dayjs(startTime).format("HH:mm"),
          endTime: dayjs(endTime).format("HH:mm"),
          availabilityStartDate: dayjs(availabilityDate).format("YYYY-MM-DD"),
        }))
      );

      const payload = {
        clientAvailability: {
          clientId: clientId,
          availabilities: resultedData,
        },
        timezone: TIMEZONE,
        ClientName: user.name,
      };

      try {
        const response = await patientAvailabilityApptCall(
          ApiCalls.AddpatientAvailabilityCall,
          payload,
          getTokenSilently
        );

        if (response) {
          history.push({
            pathname: "/appointments-availability-close",
          });
        }
        setNavigate(false);
      } catch (err) {
        setReload(true);
        console.error("[HandleReviewNavigation] Error in API call:", err);
        setNavigate(false);
      }
    }
    setNavigate(false);
  };

  const handleClose = () => {
    setReload(false);
  };

  const disabledDate = (current: any) => {
    // If the date is before today, disable it
    return current && current < today.setHours(0, 0, 0, 0);
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="primary-PatientAvailabilityBuilderReview-container">
      <div>
        <div className="Location-navigation">
          <div
            className="Navigation"
            style={{
              pointerEvents: navigate ? "none" : "auto",
              cursor: navigate ? "not-allowed" : "",
            }}
          >
            <img
              className="Home-img"
              src={checkYourWork}
              alt="Loading"
              onClick={handleReviewsBack}
            />
          </div>
        </div>

        <div className="secondary-PatientAvailabilityBuilder-container">
          <div className="primary-text">Review Availability</div>
          <div className="secondary-text">
            Please review your the availability, add more if you need to and
            click Submit.
          </div>
          <div className="availability-Date">
            <div className="availability-Date-text">
              Availability start date
            </div>
            <div className="availability-date-feild">
              <DatePicker
                value={availabilityDate}
                onChange={(e: any) => onChange(e)}
                allowClear={false}
                format="MM/DD/YYYY"
                disabledDate={disabledDate}
              />
            </div>
          </div>
          <AvailabilityCard
            key="Monday"
            heading="Monday"
            locations={setMonday}
            locationData={monday}
            forwardedRef={refMonday}
            dayOfWeek={dayOfWeek.monday}
          />
          <AvailabilityCard
            key="Tuesday"
            heading="Tuesday"
            locations={setTuesday}
            locationData={tuesday}
            forwardedRef={refTuesday}
            dayOfWeek={dayOfWeek.tuesday}
          />
          <AvailabilityCard
            key="Wednesday"
            heading="Wednesday"
            locations={setWednesday}
            locationData={wednesday}
            forwardedRef={refWednesday}
            dayOfWeek={dayOfWeek.wednesday}
          />
          <AvailabilityCard
            key="Thursday"
            heading="Thursday"
            locations={setThrusday}
            locationData={thrusday}
            forwardedRef={refThursday}
            dayOfWeek={dayOfWeek.thursday}
          />
          <AvailabilityCard
            key="Friday"
            heading="Friday"
            locations={setFriday}
            locationData={friday}
            forwardedRef={refFriday}
            dayOfWeek={dayOfWeek.friday}
          />
          <AvailabilityCard
            key="Saturday"
            heading="Saturday"
            locations={setSaturday}
            locationData={saturday}
            forwardedRef={refSaturday}
            dayOfWeek={dayOfWeek.saturday}
          />
          {emptyData === true && (
            <div className="validate-text">
              Please select at least one card to proceed
            </div>
          )}
        </div>
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          onClick={HandleReviewNavigation}
          disabled={navigate}
          loading={navigate}
          style={{ pointerEvents: navigate ? "none" : "auto" }}
        >
          {navigate ? "loading..." : "Submit"}
        </Button>
      </div>
      <Dialog
        open={reload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogue-reload"
      >
        <div className="dialogue-reload-add-container">
          <div className="dialogue-X-img">
            <img src={X} alt="Loading" onClick={handleClose} />
          </div>
          <div className="dialogue-Heading">
            Something went wrong. Please try again in a moment.
          </div>
          <div className="button">
            <Button className="Warning-button" onClick={HandleReviewNavigation}>
              Try again
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PatientAvailabilityBuilderReview;
