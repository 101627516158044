import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../Loader/Loader";
import { useAuth0 } from "../../react-auth0-spa";
import CorticaLoader from "sharedComponents/CorticaLoader/CorticaLoader";

const Login = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: "/" },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) return <Redirect to="/" />;
  else return <CorticaLoader message="Cortica Telehealth" />;
};

export default Login;
