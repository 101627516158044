import { useEffect } from "react";
import { useAuth0 } from "./react-auth0-spa";
import { getSocketUrl } from "utils/WebSockets/socket.helper";
import { useWebSocket as useWebSocketContext } from "utils/WebSockets/WebSocketProvider";
import { SOCKET_ROUTES } from "constant/AppConstants";
import { useMsal } from "@azure/msal-react";

const MainWebSocket = () => {
  const { user } = useAuth0();
  const { instance } = useMsal();
  const activeMsAccount = instance.getActiveAccount();
  const environment = process.env.REACT_APP_STAGE;
  const { updateWebSocketData } = useWebSocketContext();
  const socketUrl = getSocketUrl(environment);

  const getConnectionName = () => {
    if (user?.email) {
      return user.email;
    } else if (activeMsAccount?.username) {
      return activeMsAccount.username;
    } else {
      return undefined;
    }
  };

  const connectionUserName = getConnectionName();

  useEffect(() => {
    let socket;

    const establishWebSocketConnection = () => {
      if (connectionUserName) {
        const userEmail = connectionUserName;

        socket = new WebSocket(socketUrl);

        socket.onopen = (event) => {
          try {
            socket.send(
              JSON.stringify({
                action: SOCKET_ROUTES.register,
                name: userEmail,
              })
            );
          } catch (error) {
            console.log(error, "[establishWebSocketConnection] error", error);
            establishWebSocketConnection();
          }
        };

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (
            message.data.socketRouteMethod === SOCKET_ROUTES.crAthenaStatus ||
            message.data.socketRouteMethod === SOCKET_ROUTES.register
          ) {
            updateWebSocketData(message.data.downStreamStatus);
          }
          if (!message.data.isConnected) {
            establishWebSocketConnection();
          }
        };

        socket.onclose = (event) => {
          establishWebSocketConnection();
        };
      }
    };

    establishWebSocketConnection();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user?.email]);
};

export default MainWebSocket;
