import { Cancel_Reason } from "features/Appointments/Calendar/PatientPortalAppointmentForms/CancelAppointments/staticCancelData";
import { default_Dropdown } from "features/Appointments/Calendar/PatientPortalAppointmentForms/RescheduleAppointments/staticRescheduleData";
import { createContext, useState } from "react";

export const myContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [daysChecked, setDaysChecked] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thrusday, setThrusday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [totalWeek, setTotalWeek] = useState([]);
  const [status, setStatus] = useState(null);
  const [carePlan, setCarePlan] = useState();
  const [clientId, setClientId] = useState();
  const [availabilityDate, setAvailiabilityDate] = useState();

  //Reschedule States and cancel states//

  const [state, setState] = useState({
    selectedDate: null,
    initialDateSet: false,
    clickedIndex: -1,
    dropdown: default_Dropdown,
    textArea: "",
    rcSuggestions: undefined,
    error: false,
    newStartTime: undefined,
    newEndTime: undefined,
  });
  const [cancelDropDown, setCancelDropDown] = useState(Cancel_Reason);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prefDate, setPrefDate] = useState(undefined);
  const [globalPath, setGlobalPath] = useState({
    to: undefined,
    from: undefined,
  });
  const [cancellingAppt, setCancellingAppt] = useState();

  // multiple Flow states

  const [validateUrl, setValidateUrl] = useState(false);

  return (
    <myContext.Provider
      value={{
        status,
        setStatus,
        daysChecked,
        setDaysChecked,
        totalWeek,
        setTotalWeek,
        monday,
        setMonday,
        tuesday,
        setTuesday,
        wednesday,
        setWednesday,
        thrusday,
        setThrusday,
        friday,
        setFriday,
        saturday,
        setSaturday,
        availabilityDate,
        setAvailiabilityDate,
        carePlan,
        setCarePlan,
        clientId,
        setClientId,
        validateUrl,
        setValidateUrl,
        state,
        setState,
        prefDate,
        setPrefDate,
        globalPath,
        setGlobalPath,
        confirmLoading,
        setConfirmLoading,
        cancelDropDown,
        setCancelDropDown,
        cancellingAppt,
        setCancellingAppt,
      }}
    >
      {children}
    </myContext.Provider>
  );
};
