import axios from "axios";
import { apiRequest } from "./AllAppointmentsApi";

const Configuration = async (getTokenSilently: any) => {
  const accessToken = await getTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const PostCall = async (path: string, payload: any, getTokenSilently: any) => {
  const config = await Configuration(getTokenSilently);
  return axios
    .post(apiRequest + "/" + path, payload, config)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
};

const getCall = async (path: string, getTokenSilently: any) => {
  const config = await Configuration(getTokenSilently);
  return axios
    .get(apiRequest + "/" + path, config)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
};

export const clientClinics = async (path: string, getTokenSilently: any) => {
  return await getCall(path, getTokenSilently);
};

export const editAappointment = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const cancelAppointmentCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const addAppointmentCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const ConfirmAllApptCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const checkConversionCall = async (
  path: string,
  payload: any,
  getTokenSilently: any,
  setConverted: (converted: boolean | undefined) => void,
  setConvMsg: (msg: boolean) => void,
  setBackendError: (backendError: boolean) => void
) => {
  try {
    const response = await PostCall(path, payload, getTokenSilently);
    setConverted(response?.data?.isConverted);
    setConvMsg(response?.data?.isConverted);
  } catch (error) {
    setBackendError(true);
  }
};

export const confirmDayCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const reSyncApptCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const patientAvailabilityApptCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const clientRescheduleAndCancelCall = async (
  path: string,
  payload: any,
  getTokenSilently: any
) => {
  return await PostCall(path, payload, getTokenSilently);
};

export const checkDayStatusCall = async (
  path: string,
  payload: any,
  getTokenSilently: any,
  setIsConfirmed: (isDayConfirmed: boolean) => void,
  setDayNotAvailable: (isDayNotAvailable: boolean) => void,
  setBackendError: (backendError: boolean) => void
) => {
  try {
    const response = await PostCall(path, payload, getTokenSilently);
    setIsConfirmed(response.data.isConverted);
    response.data.isConverted
      ? setDayNotAvailable(true)
      : setDayNotAvailable(false);
  } catch (error) {
    console.log("[AllAppointmentsFunctions][checkDayStatus] error", error);
    setBackendError(true);
  }
};
