import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import AvailabilityStartBack from "assets/Availablity-start-Back.svg";
import "./patientAvailabilityReview.scss";
import Warning from "assets/WarningCircle.svg";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";

const PateintAvailabilityReview: React.FC<{}> = () => {
  const history = useHistory();
  const { validateUrl } = useContext(myContext);

  const HandlestartNavigation = () => {
    history.push({
      pathname: "/appointments-availability-start",
    });
  };

  const HandleBuilderReview = () => {
    history.push({
      pathname: "/appointments-availability-builder-review",
    });
  };
  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="primary-PatientAvailabilityReview-container">
      <div>
        <div className="Location-navigation">
          <div className="Navigation">
            <img
              className="Home-img"
              src={AvailabilityStartBack}
              alt="Loading"
              onClick={HandlestartNavigation}
            />
          </div>
        </div>
        <div className="secondary-PatientAvailabilityReview-container">
          <div className="primary-text">Check your work</div>
        </div>
        <div className="Third-PatientAvailabilityReview-container">
          <div className="Prompt">
            <div className="Warning-img">
              <img src={Warning} alt="Loading" />
            </div>
            <div className="secondary-text">
              Did you give us more than 20 hours of availability?
            </div>
          </div>
          <div className="Prompt">
            <div className="Warning-img">
              <img src={Warning} alt="Loading" />
            </div>
            <div className="secondary-text">
              Did you input all of the blocks of time you’re available on each
              day, like for a few hours in the morning and for a few hours in
              the evening?
            </div>
          </div>
          <div className="Prompt">
            <div className="Warning-img">
              <img src={Warning} alt="Loading" />
            </div>
            <div className="secondary-text">
              Did you know that giving us morning options makes it easier to
              schedule you? If you only gave us evening, then you might be put
              on a wait list.
            </div>
          </div>
        </div>
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleBuilderReview}
        >
          Review
        </Button>
      </div>
    </div>
  );
};

export default PateintAvailabilityReview;
