import { Button } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./clientCancelError.scss";
import TrobleShooting from "assets/troubleshooting.svg";
import { appointments } from "model/calender/appointments";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "../RescheduleAppointments/staticRescheduleData";
import { Cancel_Reason } from "./staticCancelData";

interface LocationState {
  appointment: appointments;
  path: string;
}

const ClientCancelError: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const {
    setState,
    setValidateUrl,
    setCancelDropDown,
    setConfirmLoading,
    setCancellingAppt,
  } = useContext(myContext);

  const { appointment, path } = location?.state;

  console.log(
    appointment,
    path,
    "[clientRescheduleProblem] appointment and path"
  );

  const handleNavigateHome = () => {
    setCancellingAppt(appointment?.id);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    setCancelDropDown(Cancel_Reason);
    setConfirmLoading(false);
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  useEffect(() => {
    setValidateUrl(true);
  }, [setValidateUrl]);

  return (
    <div className="client-cancel-error-container">
      <div className="client-cancel-error-inner-container-top">
        <span className="client-cancel-error-heading">
          We have a little problem
        </span>

        <img
          className="client-cancel-error-image"
          src={TrobleShooting}
          alt="confirmed canceled"
        />
      </div>
      <div className="client-cancel-error-inner-container-bottom">
        <span>
          Looks like something went wrong on our end, and we can't cancel your
          appointment right now. Head back to our home page and try cancelling
          again. We apologize for any inconvenience and appreciate your
          understanding.
        </span>
        <div className="client-cancel-error-button">
          <Button
            className="client-cancel-error-home"
            onClick={handleNavigateHome}
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientCancelError;
