import { Button } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./clientRescheduleProblem.scss";
import TrobleShooting from "assets/troubleshooting.svg";
import { appointments } from "model/calender/appointments";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "./staticRescheduleData";

interface LocationState {
  appointment: appointments;
  path: string;
}

const ClientRescheduleProblem: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { setState, setValidateUrl } = useContext(myContext);

  const { appointment, path } = location?.state;

  console.log(
    appointment,
    path,
    "[clientRescheduleProblem] appointment and path"
  );

  const handleNavigateHome = () => {
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    if (path === "appointments")
      history.push({
        pathname: "/appointments",
      });
    else {
      history.push({
        pathname: "/client-appointments",
      });
    }
  };

  useEffect(() => {
    setValidateUrl(true);
  }, [setValidateUrl]);

  return (
    <div className="client-reschedule-problem-container">
      <div className="client-reschedule-problem-inner-container-top">
        <span className="client-reschedule-problem-heading">
          We have a little problem
        </span>

        <img
          className="client-reschedule-problem-image"
          src={TrobleShooting}
          alt="confirmed rescheduled"
        />
      </div>
      <div className="client-reschedule-problem-inner-container-bottom">
        <span>
          Looks like something went wrong on our end, and we can't reschedule
          your appointment right now. Head back to our home page and try
          rescheduling again. We apologize for any inconvenience and appreciate
          your understanding.
        </span>
        <div className="client-reschedule-problem-button">
          <Button
            className="client-reschedule-problem-home"
            onClick={handleNavigateHome}
          >
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientRescheduleProblem;
