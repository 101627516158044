export const SOCKET_ROUTES = {
  register: "setName",
  connect: "connect",
  crAthenaStatus: "DownStreamStatus",
  disconnect: "disconnect",
};

export const ApplicationStages = {
  local: "local",
  dev: "dev",
  test: "test",
  uat: "uat",
  prod: "prod",
};

export const APPT_TYPES = {
  admin: "Admin",
  aba: "ABA",
  dt: "DT",
  med: "MED",
  fwc: "FWC",
};

export const STATE_ABBREVIATIONS = {
  california: "CA",
  texas: "TX",
};

export const EVENT_STATUS = {
  canceled: "canceled",
  scheduled: "scheduled",
  rescheduled: "rescheduled",
};
export const String_Initialiser = "";

export const Decimal_ZERO = "00";

export const APPT_LOCATION_TYPES = {
  inclinic: "In Clinic",
  telehealth: "Telehealth",
  offsite: "Off-Site",
};

export const msalRoutes = [
  "/add-new-appointment",
  "/cancel-appointment",
  "/providerappointments",
  "/edit-appointment",
];
export const CR_SYNC_AND_FAILURE_STATUS = [0, 1, 2, 3, 401, 402, 403];

export const CR_SYNC_STATUS = [0, 1, 2, 3, 4];

export const CR_FAILURE_STATUS = [0, 401, 402, 403];
