import React, { useRef, useEffect, useCallback } from "react";
import "./appointmentDetails.css";
import clock from "../../../assets/Clock.svg";
import calendar from "../../../assets/CalendarBlank.webp";
import pin from "../../../assets/MapPin.svg";
import provider from "../../../assets/provider.png";
import Header from "features/Header/Header";
import LeftCaret from "../../../assets/CaretLeft.png";
import { formatDateAndTime } from "constant/calenderView";
import { IAddress, appointments } from "model/calender/appointments";
import { ClickPosition } from "model/calender/calenderView";
import { isMobile } from "react-device-detect";
import { APPT_LOCATION_TYPES } from "constant/AppConstants";

interface IProps {
  show: Boolean;
  onClose: () => void;
  event: appointments | null;
  clickPosition: ClickPosition;
  setActiveEvent: (value: string | null) => void;
}

type PopupElementType = HTMLDivElement;

interface EventDetails {
  src: string;
  alt: string;
  text?: string | JSX.Element;
}

const AppointmentDetails: React.FC<IProps> = ({
  show,
  onClose,
  event,
  clickPosition,
  setActiveEvent,
}) => {
  const popupRef = useRef<PopupElementType | null>(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        show &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        onClose();
        const activeEventElement = document.querySelector(".active-event");
        if (activeEventElement) {
          activeEventElement.classList.remove("active-event");
        }
        setActiveEvent(null);
      }
    },
    [show, onClose, setActiveEvent]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!show) {
    return null;
  }

  
  let address: string;

  if (event?.address !== null) {
    if (event?.location_type === APPT_LOCATION_TYPES.telehealth) {
      address = event?.link;
    } else {
      event?.location_type === APPT_LOCATION_TYPES.offsite
        ? (address = Object.entries(event?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== ""
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "))
        : (address = Object.entries(event?.address as IAddress)
            .filter(
              ([key, value]) =>
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "addressLine2"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
    
  }
  

  const openMaps = () => {
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
    window.open(googleMapsUrl, "_blank");
  };

  const details: EventDetails[] = [
    {
      src: calendar,
      alt: "calendar",
      text: formatDateAndTime(event?.startAt, true),
    },
    {
      src: clock,
      alt: "clock",
      text: `${formatDateAndTime(event?.startAt)} - ${formatDateAndTime(
        event?.endAt
      )}`,
    },
    {
      src: provider,
      alt: "provider",
      text: event?.provider.split(",").join(" "),
    },
    {
      src: pin,
      alt: "pin",
      text: (
        <div className="address">
          {event?.location_type === APPT_LOCATION_TYPES.telehealth ? (
            <a
              className="address-link"
              href={address}
              target="_blank"
              rel="noopener noreferrer"
            >
              {address}
            </a>
          ) : (
            <a
            className="maps-address" href="#" onClick={openMaps}>{address}</a>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className={isMobile ? "modal-mobile" : "modal"}
        style={
          isMobile
            ? {}
            : { left: `${clickPosition.x}px`, top: `${clickPosition.y}px` }
        }
        onClick={handleClickOutside}
      >
        <div
          className={isMobile ? "modal-content-mobile" : "modal-content"}
          ref={popupRef}
        >
          {isMobile && (
            <>
              <Header />
              <div className="mobile-home">
                <div className="back-button" onClick={onClose}>
                  <img src={LeftCaret} alt="left arrow" />
                  <button className="home-button" onClick={onClose}>
                    Home
                  </button>
                </div>
              </div>
              <div className="appt-details-heading">
                <h3>Appointment Details</h3>
              </div>
            </>
          )}
          <div
            className="modal-body"
            style={{ paddingBottom: isMobile ? "65px" : "0px" }}
          >
            {/* <h4>{oneDayEvents?.title}</h4> */}
            <h4>{event?.type_name}</h4>

            {details.map((detail, index) => (
              <div key={index} className="appt-details">
                <img src={detail.src} alt={detail.alt} />
                <div className="appt-details-div">{detail.text}</div>
              </div>
            ))}
          </div>
          {isMobile && (
            <footer className="mobile-footer">
              <div className="left-side">
                <p> Copyright &copy; 2023 Cortica</p>
              </div>
              <div className="right-side">
                <p>
                  All Rights Reserved |{" "}
                  <a
                    href="https://www.corticacare.com/terms-use"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                  |
                  <a
                    href="https://www.corticacare.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </p>
              </div>
            </footer>
          )}
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
