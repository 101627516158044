import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./providerCalendar.scss";
import AppointmentDetails from "./AppointmentDetails";
import { EventApi } from "@fullcalendar/core";
import moment from "moment";
import SpinnerV2 from "../spinnerV2/spinnerV2";
import { CALENDAR_VIEW, Days, FullDays } from "../../../constant/calenderView";
import { Appointment } from "model/appointments";
import {
  ButtonDates,
  ClickPosition,
  // ClientColor,
  Idate,
} from "model/BiCalendar";
import AppointmentComponent from "./AppointmentComponent";
import { appointments } from "model/calender/appointments";
import Popup from "sharedComponents/reactHookComponents/popup/popup";
import MileageError from "sharedComponents/Error/mileageError";
import { APPT_LOCATION_TYPES } from "constant/AppConstants";

interface IProps {
  appointments: Appointment[];
  handleDataChange: (
    startStr: string,
    endStr: string,
    setLoading: boolean
  ) => void;
  softloading: boolean;
  providerId: number;
  providerName: string;
  providerEmail: string;
  providerClinicId: number;
  disableAddAppt: boolean;
  dayConfirmed: boolean;
  toDate?: string;
  prefDate?: string;
  getAppointments: (
    startStr: string,
    endStr: string,
    setLoading: boolean
  ) => void;
  startDateProps: any;
  endDateProps: any;
  setShouldPoll: (val: boolean) => void;
  setResyncCrId: (val: number) => void;
  filteredResyncAppointments: any;
  resyncDisable: boolean;
  setResyncDisable: (val: boolean) => void;
  resyncCount: number;
  setResyncCount: (val: number) => void;
  displayMileageError: boolean;
}

const ProviderCalendarView: React.FC<IProps> = ({
  appointments,
  handleDataChange,
  softloading,
  providerId,
  providerName,
  providerClinicId,
  providerEmail,
  disableAddAppt,
  dayConfirmed,
  toDate,
  prefDate,
  getAppointments,
  startDateProps,
  endDateProps,
  setShouldPoll,
  setResyncCrId,
  filteredResyncAppointments,
  resyncDisable,
  setResyncDisable,
  resyncCount,
  setResyncCount,
  displayMileageError,
}) => {
  const history = useHistory();
  const [showAppointmentDetails, setShowAppointmentDetails] =
    useState<Boolean>(false);
  const [eventDetail, setEventDetail] = useState<appointments | null>(null);

  const [clickPosition, setClickPosition] = useState<ClickPosition>({
    x: 0,
    y: 0,
  });
  const [activeEvent, setActiveEvent] = useState<string | null>(null);
  const [buttonDates, setButtonDates] = useState<ButtonDates[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const selectedOption: string = "Day";

  const [activeDay, setActiveDay] = useState<string>(new Date().toString());

  const [isPopupOpen, setPopupOpen] = React.useState<boolean>(false);

  const calendarRef = useRef<null>(null);
  const buttonDatesRef = useRef<ButtonDates[]>(buttonDates);
  const startDateRef = useRef<string>(startDate);
  const endDateRef = useRef<string>(endDate);

  const handleEventClick = (info: any) => {
    const targetElement = info.el;
    const targetRect = targetElement.getBoundingClientRect();

    const popUpX =
      targetRect.left + window.scrollX < window.innerWidth / 2
        ? targetRect.right + window.scrollX - 18
        : targetRect.left + window.scrollX - 220;
    const popUpY = targetRect.top + window.scrollY;

    setClickPosition({ x: popUpX, y: popUpY });
    setEventDetail(info.event?.extendedProps);
    setShowAppointmentDetails(true);
    if (activeEvent === info.event.id) {
      setActiveEvent(null);
    } else {
      setActiveEvent(info.event.id);
      // info.el.classList.add("active-event");
    }
  };

  const handleAddNewAppointment = () => {
    history.push({
      pathname: "/add-new-appointment", // New route
      state: {
        providerId: providerId, // Pass providerId in state
        providerName: providerName, // Pass providerName in state
        providerClinicId: providerClinicId,
        providerEmail: providerEmail,
        prevDate: moment(activeDay).toISOString(),
      },
    });
  };

  const handleEventContent = ({ event }: { event: EventApi }) => {
    return (
      <div className="appt-card">
        <p className="appt-type">{event?.title}</p>
        <p className="appt-location">
          {event?.extendedProps.location_type === APPT_LOCATION_TYPES.inclinic
            ? `${event?.extendedProps?.clinic?.name} Clinic`
            : event?.extendedProps.location_type === APPT_LOCATION_TYPES.offsite
            ? `${event?.extendedProps?.location_category}`
            : `TeleHealth`}
        </p>
        <p className="appt-provider">{event?.extendedProps?.provider_name}</p>
      </div>
    );
  };

  const checkIfWithinCurrentWeek = (startDate: string) => {
    const startOfWeek = moment().startOf("week");

    const startDateWithinWeek = moment(startDate).isSame(startOfWeek);
    return startDateWithinWeek;
  };

  const handlePlannerDateViewChange = useCallback(
    (dateArgs: any) => {
      const { startStr, endStr } = dateArgs;

      const parsedStartData: moment.Moment = moment(startStr);

      let TodaysStartDate = moment().format("YYYY-MM-DD");
      const formattedStart = parsedStartData.format("YYYY-MM-DD");
      parsedStartData.set({ minute: 0, second: 0, millisecond: 0 });

      const parsedEndData: moment.Moment = moment(endStr);

      const ifCurrentWeek = checkIfWithinCurrentWeek(formattedStart);

      parsedEndData.set({ minute: 0, second: 0, millisecond: 0 });

      setStartDate(parsedStartData.toISOString());
      setEndDate(parsedEndData.toISOString());
      const currentDay = parsedStartData.day();
      if (ifCurrentWeek) {
        setActiveDay(TodaysStartDate);
        let TodaysStateDateTime = moment(TodaysStartDate)
          .startOf("day")
          .toISOString();
        let TodaysEndDateTime = moment(TodaysStartDate)
          .endOf("day")
          .toISOString();
        if (prefDate === undefined) {
          handleDataChange(TodaysStateDateTime, TodaysEndDateTime, true);
        }
      } else if (
        currentDay === 0 &&
        moment(activeDay).format("dddd") === FullDays[0]
      ) {
        const currentSunday = parsedStartData.clone();
        const formattedStartDate = currentSunday.startOf("day").toISOString();
        const formattedEndDate = currentSunday.endOf("day").toISOString();
        handleDataChange(formattedStartDate, formattedEndDate, true);
        setActiveDay(currentSunday.toDate().toString());
      } else {
        const currentDate = prefDate
          ? new Date(prefDate)
          : parsedStartData.clone();
        // const currentSunday = parsedStartData.clone();
        handleDataChange(
          moment(currentDate).startOf("day").toISOString(),
          moment(currentDate).endOf("day").toISOString(),
          true
        );
        setActiveDay(parsedStartData.toDate().toString());
      }
    },
    [activeDay, prefDate, handleDataChange]
  );

  useEffect(() => {
    const start: Date = new Date(startDate);
    const end: Date = new Date(endDate);

    const dates = [];

    while (start <= end) {
      if (start.getDay() === 0 && dates.length === 0) {
        dates.push({ date: new Date(start), week: moment(start).isoWeek() });
      } else if (start.getDay() !== 0) {
        dates.push({ date: new Date(start), week: moment(start).isoWeek() });
      }
      start.setDate(start.getDate() + 1);
    }

    setButtonDates(dates);
  }, [startDate, endDate]);

  useEffect(() => {
    buttonDatesRef.current = buttonDates;
  }, [buttonDates]);

  useEffect(() => {
    startDateRef.current = startDate;
  }, [startDate]);

  useEffect(() => {
    endDateRef.current = endDate;
  }, [endDate]);

  useEffect(() => {
    const currentWeek = moment().isoWeek();
    const currentDate = new Date();
    const buttonDates = buttonDatesRef.current;
    if (buttonDates.length <= 0 && selectedOption === CALENDAR_VIEW.dayView) {
      prefDate
        ? setActiveDay(new Date(prefDate).toString())
        : setActiveDay(new Date().toString());
      if (prefDate) {
        handleDataChange(
          moment(prefDate).startOf("day").toISOString(),
          moment(prefDate).endOf("day").toISOString(),
          true
        );
      }
    } else {
      if (currentWeek === buttonDates[2]?.week) {
        setActiveDay(currentDate.toString());
      } else {
        setActiveDay(buttonDates[0]?.date.toString());
      }
    }
  }, [selectedOption]);

  const handleButtonClick = (date: Idate) => {
    handleDataChange(
      moment(date.date).startOf("day").toISOString(),
      moment(date.date).endOf("day").toISOString(),
      true
    );
    setShowAppointmentDetails(false);
    setEventDetail(null);
    setActiveEvent(null);
    setActiveDay(date?.date.toString());
  };

  const handleTodayButtonClick = useCallback(() => {
    const currentSelectedOption = selectedOption;
    if (currentSelectedOption === CALENDAR_VIEW.dayView) {
      const currentDate = new Date();
      handleDataChange(
        moment(currentDate).startOf("day").toISOString(),
        moment(currentDate).endOf("day").toISOString(),
        true
      );
      setActiveDay(new Date().toString());
    }
  }, [selectedOption, handleDataChange]);

  useEffect(() => {
    const todayButton = document.querySelector(".fc-today-button");
    if (todayButton) {
      todayButton.addEventListener("click", handleTodayButtonClick);
    }

    return () => {
      if (todayButton) {
        todayButton.removeEventListener("click", handleTodayButtonClick);
      }
    };
  }, [selectedOption, handleTodayButtonClick]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="primary-wrapper-provider">
        <div ref={calendarRef} className="provider-calender-view">
          <div className="page-header">
            <h2 className="page-header-h2">My Schedule</h2>
          </div>
          {displayMileageError && (
            <div>
              <MileageError></MileageError>
            </div>
          )}
          <div className="special-class">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              allDaySlot={false}
              initialView="timeGridCustomView"
              views={{
                timeGridCustomView: {
                  type: "timeGrid",
                  duration: { weeks: 1 },
                },
              }}
              slotMinTime="07:00:00"
              slotMaxTime="22:00:00"
              eventClick={handleEventClick}
              slotEventOverlap={false}
              eventContent={handleEventContent}
              headerToolbar={{
                left: "prev",
                center: "title today",
                right: "next",
              }}
              initialDate={prefDate}
              datesSet={handlePlannerDateViewChange}
            />
          </div>

          {selectedOption === CALENDAR_VIEW.dayView ? (
            <div>
              <div
                className="dayAppointments"
              >
                {buttonDates.map((date, index) => (
                  <div
                    className={`dayAppointments-1 ${
                      moment(activeDay).isSame(
                        moment(date.date.toDateString(), "ddd MMM DD YYYY"),
                        "day"
                      )
                        ? "active"
                        : ""
                    }`}
                    style={{
                      // pointerEvents: softloading ? "none" : "auto",
                      cursor: softloading ? "not-allowed" : "pointer",
                    }}
                    key={index}
                  >
                    <div
                      key={index}
                      onClick={() => handleButtonClick(date)}
                      className="dayAppointments-buttons"
                      style={{ pointerEvents: softloading ? "none" : "auto" }}
                    >
                      <div className="dayAppointments-2">
                        {Days[date.date.getUTCDay()]}
                      </div>
                      <div className="dayAppointments-3">
                        {date.date.getUTCDate()}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="add-new">
                <button
                  className="add-new-button"
                  disabled={disableAddAppt || softloading}
                  onClick={() => handleAddNewAppointment()}
                >
                  Add New Appointment
                </button>
              </div>

              {!softloading &&
              activeDay &&
              (toDate ? moment(activeDay).isSame(toDate, "day") : true) ? (
                appointments?.length === 0 ? (
                  <div className="no-appointments">
                    <p className="no-appointments-1">
                      No Upcoming Appointments Today
                    </p>
                  </div>
                ) : (
                  <div className="appointment-list-provider">
                    {appointments?.map((appointment: any) => {
                      return (
                        <AppointmentComponent
                          key={appointment.id}
                          appointment={appointment}
                          dayConfirmed={dayConfirmed}
                          activeDay={activeDay}
                          getAppointments={getAppointments}
                          startDateProps={startDateProps}
                          endDateProps={endDateProps}
                          setShouldPoll={setShouldPoll}
                          setResyncCrId={setResyncCrId}
                          filteredResyncAppointments={
                            filteredResyncAppointments
                          }
                          resyncDisable={resyncDisable}
                          setResyncDisable={setResyncDisable}
                          resyncCount={resyncCount}
                          setResyncCount={setResyncCount}
                          isPopupOpen={isPopupOpen}
                          setPopupOpen={setPopupOpen}
                        />
                      );
                    })}
                  </div>
                )
              ) : (
                <div className="load-io-top">
                  <SpinnerV2 />
                  <div className="no-appointments loading-io">
                    <p className="no-appointments-1">
                      Loading Appointments for the Day
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {showAppointmentDetails && (
            <AppointmentDetails
              show={showAppointmentDetails}
              onClose={() => setShowAppointmentDetails(false)}
              event={eventDetail}
              clickPosition={clickPosition}
              setActiveEvent={setActiveEvent}
            />
          )}
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          heading="Appointment Failed to Sync"
          paragraph="The appointment has failed to sync to Central Reach. Please send a IT Help Desk ticket to report issue."
        />
      </div>
    </>
  );
};

export default React.memo(ProviderCalendarView);
